@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '@angular/cdk/overlay-prebuilt.css';
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./assets/scss/main.scss";
@import "./assets/scss/common.scss";

@font-face {
  font-family: "A Calling";
  src: url("/assets/fonts/ACalling.eot");
  src: url("/assets/fonts/ACalling.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/ACalling.woff2") format("woff2"),
    url("/assets/fonts/ACalling.woff") format("woff"),
    url("/assets/fonts/ACalling.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Constantia";
  src: url("/assets/fonts/Constantia-Bold.eot");
  src: url("/assets/fonts/Constantia-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Constantia-Bold.woff2") format("woff2"),
    url("/assets/fonts/Constantia-Bold.woff") format("woff"),
    url("/assets/fonts/Constantia-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

// custom global styles
@import "./app/app-core/styles/global-styles.scss";

body {
  padding: 0 !important;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-overflow-scrolling: auto;
  @media only screen and (max-height: 420px) {
    top: 0 !important;
  }
}

img {
  max-width: 100%;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  @media (min-width: 1200px) {
    max-width: 1190px;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }
}

app-root,
app-page,
app-profile,
app-page-profile-header,
app-profile-dogs,
app-dog-detail,
app-profile-user,
app-profile-user-cover-image,
app-products-and-credits,
app-profile-user-detail,
app-page-profile-footer,
app-profile-user-chat,
app-description-dialog {
  display: block;
}

app-page, app-profile,app-profile-user-chat, app-products-and-credits {
  // padding-top: 102px !important;

  // @media only screen and (max-width: 1024px) {
  //   padding-top: 72px !important;
  // }

  // @media only screen and (max-width: 767px) {
  //   padding-top: 56px !important;
  // }

  // @media only screen and (max-height: 420px) {
  //   padding-top: 0 !important;
  // }
}

.my-6 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.my-6-imp {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.mt-6 { margin-top: 60px; }
.mt-6-imp { margin-top: 60px !important; }

.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1310px;
}

header.user-profile-page {
  box-shadow: 0 0 0 2px rgba(50, 98, 145, 0.2);
}

.sticky-header {
  background-color: rgba(255, 255, 255, 0.9);
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.primary-color {
  color: #326291;
}

.pos-vertical-align {
  top: 40%;
  transform: translateY(-50%);
  right: 14px;
  text-align: center;
}

.pos-right-20 {
  right: 20px;
}

.cus-pb-30 {
  @media only screen and (max-width: 767px) {
    padding-bottom: 24px !important;
  }
}

.btn-height-45 {
  height: 45px;
  font-size: 16px !important;
  font-weight: 600 !important;

  @media only screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}

.ng-select .ng-select-container {
  height: 50px !important;
  border-radius: 0.45rem;
  color: #000;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgb(102, 102, 102, 0.8);
}

.ng-select.invalid-alert {
  border: none !important;
}

.ng-select.invalid-alert .ng-select-container {
  border: solid 2px #ff6666;
  border-radius: 0.45rem !important;
}

span.ng-clear-wrapper.ng-star-inserted {
  display: none;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.mat-expansion-panel:focus,
.mat-expansion-panel:hover {
  background: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #326291 !important;
  color: #ffffff !important;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: initial;
}

button.disabled {
  opacity: 0.65;
}

.disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.custom-select {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+CiAgPHBhdGggaWQ9IlNoYXBlXzgiIGRhdGEtbmFtZT0iU2hhcGUgOCIgZD0iTTAsMS40MTUsNC45NDcsNiwwLDEwLjU4NSwxLjUyNywxMiw4LDYsMS41MjcsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyKSByb3RhdGUoOTApIiBmaWxsPSIjMzI2MjkxIi8+Cjwvc3ZnPgo=")
    right 0.75rem center/12px 10px no-repeat;
}

.form-control,
.custom-select {
  border-color: #e6e7e9;
  font-size: 14px;
  line-height: 17px;
  height: auto;
  padding: 13px 18px;
  border-radius: 8px;

  &:focus {
    box-shadow: none;
    border-color: #fed66b;
  }
}

.btn-fill-yellow {
  background-color: #fed66b;
  border-radius: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #326291;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.btn-fill-yellow:hover {
  text-decoration: none;
}

.btn-fill-white {
  background-color: #ffff !important;
  border-radius: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #326291 !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.btn-fill-white:hover {
  text-decoration: none;
}

.btn-fill-blue {
  background-color: #326291;
  border: #326291 1px solid !important;
  color: #ffffff;
  margin-right: 25.5px;
  border: none;

  &:hover {
    color: #ffffff;
  }
}

.btn-blue-outline {
  background-color: #ffffff;
  border: solid 1px #326291;
  width: 385px;
  height: 55px;
  color: #326291;
}

.btn-theme {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #326291;
  border: 1px solid #326291;
  color: #ffffff;
  padding: $button-md-padding;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  max-width: 250px;
  width: 100%;

  &.btn-fill-yellow {
    background-color: #fed66b;
    color: #326291;
    border-color: #fed66b;
  }
}
a.btn-theme:hover {
  color: #fff;
}

.btn-theme-border {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: transparent;
  border: 1px solid #326291;
  color: #326291;
  padding: $button-md-padding;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  max-width: 250px;
  width: 100%;
}

.btn-secondary {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: transparent;
  border: 1px solid #326291;
  color: #326291;
  padding: $button-md-padding;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: #326291;
    background-color: transparent;
  }

  @media (max-width: 575px) {
    padding: $button-xs-padding;
    font-size: $button-xs-font-size;
  }
}

.btn-sm {
  @media (max-width: 575px) {
    padding: $button-xs-padding !important;
    font-size: $button-xs-font-size !important;
  }
}

.btn-border-white {
  padding: 10px 55px;
  border: 2px solid #e6e6e6;
  border-radius: 3rem;
  max-height: 45px;
  color: #fff;
  white-space: nowrap;

  &:hover {
    color: #fff;
  }

  @media (max-width: 767px) {
    border: 1px solid #e6e6e6;
    padding: 10px 30px;
  }
}

.cus-name-width {
  max-width: 63%;
  overflow: hidden;
  text-overflow: ellipsis;
}

:focus-visible,
*:focus {
  outline: none;
}

.btn {
  &.focus,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn-no-hover {
  &:hover {
    color: inherit;
    background-color: unset;
  }
}

.btn-no-text-hover {
  &:hover {
    color: inherit;
  }
}

.bg-btn-icon-y {
  background-color: #fed66b;
  padding: 0.73px 6.4px;
}

.mat-select {
  font-family: "Poppins", sans-serif;
}

.box-shadow-cus {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.bg-326291 {
  background-color: #326291 !important;
}

.bg-E0E0E0 {
  background-color: #e0e0e0;
}

.color-blue-important {
  color: #326291 !important;
}

.color-green {
  color: #669966;
}

.cus-my-20-btn {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

.card-icon-new {
  padding: 6px !important;
  min-width: 42px !important;
  width: 42px !important;
  height: 42px !important;
  background-color: $blue-1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img { 
    width: 30px !important;
    height: 30px !important;
  }
}

.section-border-sm {
  @media (max-width: 991px) {
    border-color: $gray-1;
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-style: solid;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.section-border-lg {
  @media (max-width: 1199px) {
    border-color: $gray-1;
    border-width: 1px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-style: solid;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;

  .paw-notification {
    padding: 4px 15px;
    border: solid 1px #326291;
    border-radius: 20px;
    color: #326291;
    width: 80px;
    height: 40px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;

    // -webkit-transition: all 0.3s;
    // -moz-transition: all 0.3s;
    // -ms-transition: all 0.3s;
    // -o-transition: all 0.3s;
    // transition: all 0.3s;
    svg {
      vertical-align: inherit;
    }

    span {
      vertical-align: sub;
      padding-left: 9px;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
    }
  }

  .paw-notification:hover {
    text-decoration: none;
  }
}

.header-main {
  background-color: #fff;

  .navbar {
    .navbar-brand {
      padding: 0;

      img {
        // -webkit-transition: all 0.3s;
        // -moz-transition: all 0.3s;
        // -ms-transition: all 0.3s;
        // -o-transition: all 0.3s;
        // transition: all 0.3s;
        width: 140px;

        @media only screen and (max-width: 767px) {
          width: 85px;
        }
      }
    }
  }

  .navbar-nav {
    align-items: center;

    @media only screen and (max-width: 991px) {
      flex-direction: unset;
    }

    li.nav-item {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }

    .nav-link {
      padding-top: 0;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
      // -webkit-transition: all 0.3s;
      // -moz-transition: all 0.3s;
      // -ms-transition: all 0.3s;
      // -o-transition: all 0.3s;
      // transition: all 0.3s;
    }
  }
}

.openside-nav {
  overflow: hidden;
}

li.nav-item-mobile {
  // display: none;
}

.navbar-nav {
  .nav-item.active {
    a {
      border-bottom: solid 3px #326291;
      color: #326291;
    }
  }
}

.navbar-nav {
  li.nav-item {
    a {
      color: rgba(0, 0, 0, 0.8);
      padding-bottom: 4px;
    }
  }
}

.navbar-nav.navbar-userprofile {
  li {
    a {
      color: #797979;
    }
  }

  .nav-item.dropdown {
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 194px;
    height: 43px;
    display: flex;
    align-items: center;

    // -webkit-transition: all 0.3s;
    // -moz-transition: all 0.3s;
    // -ms-transition: all 0.3s;
    // -o-transition: all 0.3s;
    // transition: all 0.3s;
    a {
      color: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      padding-bottom: 0;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      height: 100%;

      div {
        margin-left: 10px;
      }

      span {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        overflow: hidden;

        img {
          border-radius: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        + div {
          white-space: nowrap;
          width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .dropdown-menu {
      a {
        font-size: 14px;
        padding-left: 0;
        padding-right: 0px;
      }
    }
  }

  .dropdown-toggle::after {
    content: url("/assets/img/ic-drop-down.png");
    //    transform: rotate(90deg);
    width: 4px;
    border: none;
    position: absolute;
    right: 17px;
    top: 9px;
  }
}

.navbar-nav {
  li.nav-item.app-btn {
    font-size: 18px;
    border-radius: 2em;
    padding: 4px 20px;
    height: 40px;
    cursor: pointer;
    background-color: #fed66b;

    a {
      color: #326291;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
    }

    :hover {
      text-decoration: none;
    }
  }
}

.navbar-nav {
  li.nav-item.sidebar {
    a {
      font-size: 21px;
      cursor: pointer;
      color: #326291;
      background-color: #fed66b;
      border-radius: 0.3em;
      padding: 5.2px 10.6px;
      height: 40px;
      box-shadow: 0px 0px 30px #0000000f;
      -webkit-box-shadow: 0px 0px 30px #0000000f;
      -moz-box-shadow: 0px 0px 30px #0000000f;
    }
  }

  li.nav-item.sidebar.no-sidebar-bg {
    margin: 0;
    padding: 0;

    a {
      // background-color: transparent;
      // box-shadow: none;
      // font-size: 26px;
      // color: #797979;
    }
  }
}

.sidenav {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  padding-top: 0px;

  .nav-side-header {
    border-bottom: solid 1px #e6e7e9;
    padding: 0 30px 0 30px;
    min-height: 90px;
  }

  ul li a,
  .mat-expansion-panel-header .mat-expansion-panel-header-title {
    padding: 8px 30px 8px 30px;
    text-decoration: none;
    font-size: 16px;
    color: #424242;
    display: block;
    transition: 0.3s;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    &:hover {
      text-decoration: none;
      background-color: transparent;
      color: #326291;
    }

    .nav-icon {
      width: 25px;
      height: 25px;
      margin-right: 20px;
    }
  }

  ul li:has(.separator) {
    margin: 20px 30px;
  }

  ul li .separator {
    width: 100%;
    height: 1px;
    background-color: $gray-2;
  }

  .mat-expansion-panel-header {
    padding-left: 0px;
    padding-right: 25%;
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background-color: transparent;
    color: #326291;
  }

  .mat-expanded {
    .mat-expansion-panel-header-title {
      color: #326291;
    }

    .mat-expansion-indicator {
      &:after {
        color: #326291;
      }
    }
  }

  ul li a:hover {
    color: #326291;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-decoration: underline;
  }

  ul li:first-child a:hover {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-decoration: none;
    color: #000000;
  }

  ul li {
    img.line-img {
      padding: 8px 30px 8px 30px;
    }

    &.nav-item {
      display: block;
    }
  }

  ul {
    > li {
      &.nav-item {
        height: auto;
        width: 100%;
      }
    }
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 25px;
    top: 7px;
    content: url("/assets/img/ic_up-arrow.png");
    border: none;
  }

  .dropdown-menu {
    left: 10px;
  }
}

.paw-img-mw {
  width: 33px !important;
  min-width: 33px;
  max-width: 33px;
  padding: 3px;
}

// Registration Page 1 Css Start
.registration-page {
  section {
    margin: 100px 0 20px 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    position: relative;

    .registration-form {
      background: url("./assets/img/ic_paw.png");
      background-position: 100% 60%;
      background-repeat: no-repeat;

      &.anmeldung-abschliessen {
        background-position: 100% 90%;
      }
    }

    .registration-form.registration-pass-email {
      background-position: 60% 67%;
    }

    h2 {
      color: #326291;
      font-family: "Constantia";
      // font-weight: bold;
      // letter-spacing: 4px;
      line-height: 32px;
      font-size: 32px;
      // letter-spacing: 2px;
    }

    h4 {
      color: #52bbd5;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 18px;
    }

    p {
      line-height: 27px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      letter-spacing: 0.3px;

      a {
        color: #326291;
        text-decoration: underline;
        padding-left: 5px;
      }
    }

    .login-link {
      margin-bottom: 3em;
    }

    .register-first-desc {
      //   opacity: 0.6;
      letter-spacing: 0.17px;
    }

    form {
      .form-row {
        margin: 10px 0;
      }

      .form-row.form-row-pd {
        padding: 0;

        .pf {
          padding-left: 0px;
        }

        .pr {
          padding-right: 0px;
        }
      }

      .form-group {
        ::placeholder {
          color: #98b0c8;
        }
      }

      .form-control {
        // color: #98b0c8;
        color: #797979 !important;
        border-radius: 0.45rem;
        height: 50px;
        border: 1px solid #e0e0e0;

        &::placeholder {
          color: #797979;
        }
      }

      .input-group {
        input.form-control::placeholder {
          color: #98b0c8;
        }
      }

      .form-control:active,
      .form-control:focus,
      .form-control:focus:active {
        // color: #98b0c8;
        color: #797979 !important;
      }

      select.form-control:not([size]):not([multiple]) {
        height: calc(2.38rem + 2px);
      }

      .custom-control-label {
        font-size: 12px;
        line-height: 20px;

        a {
          color: #000000;
          text-decoration: underline;
        }
      }

      .btn.btn-register {
        background-color: #326291;
        color: #ffffff;
        border-radius: 2em;
        padding: 7px 40px;
        margin-top: 25px;
        // margin-bottom: 45px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
      }

      .btn.btn-register.btn-register-step-one {
        padding: 7px 43px;
      }

      .btn.btn-register.btn-forgot {
        padding: 7px 17px;
        margin-bottom: 30px !important;
      }

      .btn.btn-register.btn-login {
        padding: 7px 42px;
      }

      select.form-control.drop-dwn-arrow-rem {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        position: relative;
      }

      .fa.fa-angle-down {
        position: absolute;
        pointer-events: none;
        top: 17px;
        right: 22px;
        color: #98b0c8;
        font-weight: bold;
      }

      .fa.fa-eye {
        position: absolute;
        top: 17px;
        right: 22px;
        color: #98b0c8;
        font-weight: bold;
        cursor: pointer;
      }

      .fa.fa-eye-slash {
        position: absolute;
        top: 17px;
        right: 22px;
        color: #98b0c8;
        font-weight: 700;
        cursor: pointer;
      }

      .invalid-alert {
        border: solid 2px #ff6666;
      }

      .invalid-alert-message {
        color: #ff6666;
      }

      .register-checkbox {
        font-size: 16px;
        color: #424242;
        width: 90%;

        a {
          color: #424242;
          text-decoration: underline;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
        }
      }
    }

    &.forgot-password-box {
      @media only screen and (min-width: 1200px) {
        margin-bottom: 0px;
        padding-top: 10px;
      }
    }
  }

  .registration-form {
    .send-mailbox {
      margin-bottom: 34.2px;
      width: 133.99px;
    }

    .neuame-heading {
      margin-bottom: 30px;
    }

    .step-text-descrip {
      margin-bottom: 27px;
      color: rgba(0, 0, 0, 0.6);
    }

    .step-text-descrip.step-text-descrip-bold {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  // Registration Page 2 Css Start
  .input-group-text {
    background-color: #ffffff;
    border-left: 0;
  }

  .input-group {
    .input-group-icon {
      border-right: 0;
    }
  }

  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .map-popup-link {
    color: #326291;
    text-decoration: underline;
  }

  .successfully-mesg {
    background-color: rgba(102, 153, 102, 0.3);
    border-radius: 0.5em;
    padding: 10px 15px;
    display: inline-block;
    color: #669966;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  // Registratino Paasword Email
  .registration-password-email {
    .registration-form {
      background-position: 70% 64%;
    }
  }
}

.registration-page {
  section {
    .registration-form.login-form {
      h2 {
        margin-bottom: 19px;
      }

      .login-small-heading-text {
        margin-bottom: 10px;
      }

      form {
        .btn.btn-register {
          margin: 33px 0px 34px 0;
        }
      }
    }
  }

  .step-one-form-box {
    @media (min-width: 1200px) {
      margin-top: 150px;
    }
  }
}

.registration-form.forgot-page {
  section {
    p {
      margin-bottom: 10px;
    }
  }
}

// Footer Css Start
footer {
  background-color: #326291;
  vertical-align: middle;
  padding: 15px 0px;
  text-align: center;

  .app-btn {
    font-size: 16px;
    border-radius: 2em;
    padding: 7px 20px;
    cursor: pointer;
    color: #326291;
    background-color: #fed66b;
    display: inline-block;
    margin: 4px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    height: 40px;
  }

  .app-btn:hover {
    text-decoration: none;
  }

  ul.footer-helplinks {
    padding: 0px;
    margin: 0;

    li {
      display: inline-block;
      cursor: pointer;

      a {
        color: #ffffff !important;
        padding: 0px 0px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 14px;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    li::after {
      content: "|";
      color: #ffffff;
      display: inline-block;
      margin-left: 6px;
      margin-right: 6px;
    }

    li:last-child::after {
      content: "";
    }
  }

  .ic_facebook {
    fill: #ffffff;

    .a {
      fill: #354f87;
    }
  }

  .ic_twiiter {
    fill: #ffffff;

    .a {
      fill: #448dbf;
    }
  }

  .copyright-text {
    color: #ffffff;
    margin-bottom: 0px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  ul.social-links {
    padding: 0px;
    margin: 0px;

    li {
      display: inline-block;
      cursor: pointer;
      margin: 0 3px;

      img {
        width: 30px;
      }
    }
  }
}

// overlay background Css Start
.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  top: 0;
  transition: all 0.5s ease-in-out;
}

.overlay-transition {
  visibility: visible;
  opacity: 1;
}

.drop-dwn-arrow-rem {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;

  .sidenav .dropdown-toggle:after {
    position: absolute;
    right: 30px;
    top: 15px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
  }
}

.register-step-two-heading {
  margin-bottom: 28px;
}

.register-step-two-form {
  margin-top: 5em;
}

.reset-password-heading {
  letter-spacing: 1.5px !important;
  margin-bottom: 20px;
}

//    user-profile-content-box css start
.user-profile-content-box {
  margin: 0;

  .user-profile-bg-box {
    position: relative;
    z-index: 1;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    height: 271px;
    overflow: hidden;
    object-fit: cover;

    @media (min-width: 1200px) {
      height: 350px;
    }

    .dog-profile-bg {
      height: 100%;
      object-fit: cover;
    }

    .edit-user-profile-bg {
      position: absolute;
      right: 32px;
      bottom: 22px;

      .ic_edit_camera {
        padding-right: 7.5px;
      }

      a {
        background-color: #fed66b;
        border-radius: 3em;
        width: 175px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #326291;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;

        @media screen and (max-width: 767px) {
          width: 40px;

          .ic_edit_camera {
            padding-right: 0px;
            width: 15px;
          }

          > span {
            display: none;
          }
        }
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  .user-profile-details-relative {
    height: 127px;

    .user-profile-details-box {
      display: flex;
      height: 187px;
      border: solid 1px #e6e7e9;
      border-radius: 0 0 10px 10px;
      width: 100%;
      position: absolute;
      top: -60px;
      align-items: flex-end;
      padding-bottom: 21px;

      .edit-profile-btn {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
      }

      .media {
        margin-left: 24px;

        .img-profile-view {
          width: 83px;
          height: 83px;
          display: block;
          overflow: hidden;

          @media (max-width: 767px) {
            width: 50px;
            height: 50px;
            min-width: 50px;
          }

          img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .media-body {
          .name-view {
            h5 {
              font-family: "Poppins", sans-serif;
              font-weight: 600;
              font-size: 22px;
              margin-right: 11px;
              margin-bottom: 14px;
              color: #333333;
            }

            .mark-right {
              margin-left: 9px;

              .a {
                fill: #099709;
              }
            }
          }

          p.ic-location {
            font-size: 16px;
            color: #333333;
            font-family: "Poppins", sans-serif;
            font-weight: 400;

            .ic_locationmap {
              margin-right: 6px;
            }
          }

          .notification-mb {
            display: none;
          }
        }
      }

      a.user-profile-edit-btn {
        background-color: #fed66b;
        border-radius: 3em;
        width: 178px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #326291;
        margin-bottom: 1.4em;
        margin-right: 15px;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        cursor: pointer;
      }

      a.user-profile-edit-btn {
        text-decoration: none;
      }

      .user-profile-edit-btn svg.ic_edit {
        padding-right: 8px;
      }
    }
  }

  .user-profile-other-information-box {
    display: block;
    width: 100%;
    padding-top: 33px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;

    .ic_warning {
      display: flex;
      align-items: center;
      color: #ff6666;
      font-size: 12px;
      margin: 0px 3px 13px 3px;

      img {
        margin-right: 12px;
        width: 12px;
        height: 11px;
      }
    }

    .check-grap-btn {
      background-color: #fed66b;
      border-radius: 8px;
      width: 402px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #326291;
      margin-bottom: 30px;
      margin-right: 15px;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;

      img:first-child {
        margin-right: 13px;
      }

      svg:last-child {
        margin-left: 16px;
      }
    }

    .check-grap-btn:hover {
      text-decoration: none;
    }

    .btn-blue-fill-outline {
      .btn-blue-fill,
      .btn-blue-outline {
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin-bottom: 30px;
      }

      .btn-blue-fill {
        background-color: #326291;
        width: 264px;
        height: 55px;
        color: #ffffff;
        margin-right: 25.5px;
        border: none;
      }

      .btn-blue-outline {
        background-color: #ffffff;
        border: solid 1px #326291;
        width: 385px;
        height: 55px;
        color: #326291;
      }
    }
  }

  .tab-options-box {
    .tab-heading {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 18px;
      color: #326291;
      margin-bottom: 30px;
      display: inline-flex;
      align-items: center;

      //width: 44%;
      svg.ic_info_icon {
        margin-left: 9.1px;
      }
    }

    .select-option-heading {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: #326291;
      margin-bottom: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }

    ul.tab-list {
      padding: 0;

      li {
        border-bottom: solid 3px #e0e0e0;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 16px;
        list-style-type: none;
        width: 100%;
        padding-bottom: 13px;
      }

      a {
        color: #797979;
        text-decoration: none;
        padding-bottom: 13px;
      }

      li.active a {
        color: #326291;
        border-bottom: solid 3px #326291;
      }
    }

    .navbar-nav.navbar-userprofile.navbar-filter-dropdown {
      margin-left: 16px;
      margin-right: 16px;
      flex-direction: inherit;
      display: inline-flex;
      width: 132px;

      li {
        font-size: 12px;
        height: 35px;
        margin: 0;
        border: solid 2px rgba(152, 176, 200, 0.2);
        width: 100%;

        a {
          // color: #6F7E90;
          color: #326291;
          font-size: 12px;
          padding-bottom: 0px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          padding-top: 0px;
        }

        .dropdown-menu {
          transform: translate3d(-2px, -115px, 0px) !important;

          a {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 12px;
          }
        }
      }

      li > a:after {
        content: url("/assets/img/ic_up-arrow.png");
        top: 6px;
      }
    }

    .dog-filter-box {
      width: 100%;
      justify-content: flex-end;
      display: inline-flex;

      .ic_search-icon {
        left: 12px;
        top: 11px;
      }

      .search_input {
        text-indent: 20px;
        font-size: 12px;
        height: 35px;
        color: #6f7e90;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        border-radius: 8px;
        border: solid 2px rgba(152, 176, 200, 0.2);
      }

      .btn.btn-filter {
        border: solid 1px #326291;
        color: #326291;
        background-color: #ffffff;
        height: 35px;
        width: 100px;
        margin: 0 15px;
        font-size: 12px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        border-radius: 8px;
      }

      .ic_arrow-down-icon {
        right: 20px;
        top: 11px;
      }

      .btn-fill-yellow-box {
        .btn-fill-yellow {
          background-color: #fed66b;
          border-radius: 8px;
          width: 153px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #326291;
          font-size: 12px;
          font-family: "Poppins", sans-serif;
          font-weight: 600;
        }
      }
    }

    .dog-details-box {
      background-color: #ffffff;
      border-radius: 8px;
      position: relative;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
      cursor: pointer;
      margin-bottom: 30px;
      overflow: hidden;

      .overflow-hidden {
        height: 160px;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .highlight-dog-view {
          &:before {
            content: "";
            border-width: 45px 65px 30px 45px;
            border-style: solid;
            border-color: #fed66b transparent transparent #fed66b;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
          }

          .position-box {
            position: absolute;
            left: 10px;
            top: 5px;
            color: #326291;
            font-weight: 500;
            font-size: 10px;
            text-transform: capitalize;

            .img-h {
              width: 24px;
              height: 24px;
              display: block;
              overflow: hidden;
              border-radius: 100%;
              margin-top: 8px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            h4 {
              font-size: 8px;
              color: #326291;
              font-weight: 500;
              font-family: "Poppins", sans-serif;
              text-transform: capitalize;
            }
          }
        }

        img {
          // transform: scale(1.7);
          overflow: hidden;
          text-align: center;
        }
      }

      .dog-details {
        padding: 6px 10px;
        display: flex;
        align-items: center;

        h4 {
          white-space: nowrap;
          width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 20px;
        }

        h4,
        p {
          font-size: 14px;
          color: #424242;
          margin: 0;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
        }

        .dog-birthday-icon {
          margin-right: 5px;
        }

        p {
          color: #326291;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          position: relative;
          top: 2px;
          white-space: nowrap;
        }
      }

      .dog-dropdown {
        svg.dotted-popup {
          position: absolute;
          top: 8px;
          right: 8px;
          background-color: rgba(0, 0, 0, 0.6);
          padding: 0.5px 6.5px;
          height: 13px;
          border-radius: 3em;
          width: 30px;
          cursor: pointer;
        }

        .dog-dropdown-menubox {
          background-color: rgba(50, 98, 145, 0.8);
          padding: 11.5px 10px;
          display: none;
          position: absolute;
          transform: translate3d(2px, 30px, 0px) !important;
          right: 10px;
          top: 0;
          border-radius: 8px;
          width: 82%;

          ul.nav {
            li {
              a {
                color: #ffffff;
                font-size: 12px;
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                margin-bottom: 0px;
                padding: 0;
                padding-bottom: 12px;
              }

              .dropdown-toggle::after {
                border: none;
              }
            }
          }
        }
      }

      .dog-dropdown:hover .dog-dropdown-menubox {
        display: block;
      }
    }

    .btn.btn-more-dogs {
      border: solid 1px #326291;
      background-color: #ffffff;
      color: #326291;
      display: none;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
}

// User profile background edit popup css Start
.edit-profile-picture-box {
  .modal-body {
    padding-top: 57px;
    position: relative;
    width: 100%;

    h4 {
      color: #326291;
      font-size: 22px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      width: 100%;
      text-align: center;
      margin-bottom: 22px;
    }

    .ic_close {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      z-index: 99;
    }

    .edit-user-profile-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 0;
      right: 0;
      max-width: 200px;
      width: 100%;
      height: 40px;
      bottom: 0px;
      margin: 0 auto;
      font-size: 14px;

      .btn {
        border-radius: 3em;
        width: 100%;
      }
    }

    image-cropper {
      max-height: calc(100vh - 350px);

      @media (max-width: 767px) {
        max-height: calc(100vh - 226px);
      }

      + .edit-user-profile-btn {
        position: absolute;
        bottom: 15px;
      }
    }

    .btn-speichern {
      width: 100%;
      height: 35px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3em;
      margin: 0 auto;
      margin-top: 24px;
      margin-bottom: 12px;
      max-width: 200px;
    }

    // User Edit Detils Popup Css Start
    .user-edit-profile-d {
      .heading-one {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 6px;
        padding-left: 15px;
        padding-right: 15px;
      }

      .descr-one,
      .descr-two,
      .descr-three {
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: #666666;
        padding-left: 15px;
        padding-right: 15px;
      }

      .descr-three {
        padding: 0;
      }

      .form-group {
        padding-left: 15px;
        padding-right: 15px;

        label {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          font-family: "Poppins", sans-serif;
          font-weight: bold;

          @media only screen and (max-width: 375px) {
            font-size: 12px;
          }
        }

        input,
        select {
          border-radius: 8px;
          border: solid 1px #e6e7e9;
          color: #000;
          font-size: 14px;
          height: 45px;
        }

        .input-group {
          input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0;
          }

          .input-group-text {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            font-size: 12px;
            background: #fed66b;
            color: #326291;
            padding: 5px;
            width: 30px;
            border-left: 0px;
            display: flex;
            justify-content: center;
            border-color: #fed66b;
          }
        }

        input::placeholder,
        select::placeholder {
          color: #326291;
        }

        .goglemap-hyper-link {
          color: #326291;
          text-decoration: underline;
          font-size: 14px;
        }

        .input_location::placeholder,
        select.input_location {
          color: #666666;
          font-size: 13px;
        }

        .deine-sprachen-multi-dropdwn {
          .form-group {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .drop-down-geschl {
        position: relative;

        .ic_down-arrow {
          transform: rotate(-90deg);
        }

        .ic_down-arrow,
        .ic_calendar,
        .ic_locationmap {
          position: absolute;
          right: 31px;
          top: 45px;
        }
      }

      .btn-blue-outline {
        width: 230px;
        height: 40px;
        border-radius: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #326291;
        margin-left: 10px;
        margin-right: 10px;
      }

      .btn-fill-blue {
        max-width: 242px;
        border-radius: 3em;
        height: 40px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 0;
        width: 100%;
      }

      .deine-sprachen-multi-dropdwn {
        .btn-close {
          position: absolute;
          top: 9px;
          right: 20px;
          width: 25px;
          cursor: pointer;
        }

        .form-group {
          padding-left: 10px;
          padding-right: 10px;

          .ng-select {
            .ng-select-container {
              font-size: 14px;
              color: rgba(102, 102, 102, 0.8);
            }
          }
        }
      }

      .user-profile-edit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 130px;
        overflow: hidden;

        @media (max-width: 767px) {
          width: 82px;
          height: 82px;
        }

        img {
          border-radius: 5em;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .user-edit-camera {
          position: absolute;
          bottom: 0px;
          right: 40px;
          background-color: #c9cfd8;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
  }
}

.edit-profile-details-box,
.edit-profile-picture-box {
  width: 754px;
  margin: 0 auto;
}

.cdk-overlay-container[platform] {
  display: none !important;
}

.cdk-overlay-container .mat-dialog-container {
  padding: 0;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 991px) {
  header {
    box-shadow: 0 0px 8px 0px #00000028;
  }

  li.nav-item-mobile {
    display: block;
  }

  .register-step-two-heading {
    margin-bottom: 18px;
  }

  .register-step-two-form {
    margin-top: 0;
  }

  .registration-page .map-popup-link {
    width: 100%;
    display: block;
    padding: 0;
    line-height: 17px;
  }

  .registration-page
    .registration-form
    .step-text-descrip.step-text-descrip-bold {
    color: rgba(0, 0, 0, 0.6);
  }

  .registration-page .map-popup-link {
    opacity: 1;
  }

  .registration-page {
    section {
      margin: 100px 0px;

      h2 {
        font-size: 24px;
        font-weight: bold;
      }

      h4 {
        font-size: 14px;
        margin-bottom: 24px;
      }

      p {
        font-size: 14px;
      }

      p.login-link {
        margin-bottom: 0;

        span {
          display: flex;

          a {
            margin-left: 3px !important;
          }
        }
      }

      p.register-first-desc {
        line-height: 21px;
        font-size: 14px;
        letter-spacing: 0.17px;
      }

      form {
        .form-control {
          height: 45px;
        }

        .fa.fa-angle-down,
        .fa.fa-eye {
          top: 15px;
        }

        .pf.half-input,
        .pr.half-input {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .form-group {
        ::placeholder,
        .ng-select .ng-select-container .ng-value-container .ng-placeholder {
          font-size: 14px;
        }
      }

      form {
        .form-control {
          height: 45px;
        }

        .fa.fa-angle-down,
        .fa.fa-eye {
          top: 15px;
        }

        .pf.half-input,
        .pr.half-input {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .registration-form {
        background-position: 100% 60%;

        // background-size: 102px 102px;
        .send-mailbox {
          margin-bottom: 15px;
          width: 114.99px;
        }

        .neuame-heading {
          margin-bottom: 20px;
        }

        .step-text-descrip {
          margin-bottom: 15px;
        }
      }

      .registration-form.forgot-form {
        background-position: 80% 110%;
        background-size: 170px 170px;
      }

      .registration-form.login-form {
        background-position: 85% 105%;

        h2 {
          margin-bottom: 10px;
          font-weight: bold;
        }

        p.login-small-heading-text {
          font-size: 14px;
          margin-bottom: 2px;
        }

        form {
          .login-small-heading-text {
            font-size: 14px;
            margin-bottom: 13px;
          }

          .btn.btn-register {
            margin: 25px 0px 22px 0;
          }

          .form-check-label {
            font-size: 14px;
          }

          .form-check-input {
            // margin-top: 7px;
          }
        }
      }
    }

    .navbar {
      padding-left: 0;
      padding-right: 0;
      //    box-shadow: 0px 0px 6px 0px rgba(0 0 0 / 16%);
    }

    .navbar-nav {
      li.nav-item {
        margin: 0;
      }
    }

    .register-checkbox {
      width: 100%;
    }
  }

  .mboile-pd-0 {
    padding: 0 !important;
  }

  .ng-select .ng-select-container {
    height: 45px !important;
  }

  footer {
    ul.footer-helplinks {
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;

      li {
        a {
          padding: 3px 4px;
          display: inline-block;
        }
      }
    }

    .copyright-text {
      margin: 0 auto;
      margin-bottom: 10px;
      width: 90%;
    }
  }

  .overlay {
    display: none;
  }

  .dog-profile-category-box {
    .pl-mb {
      padding-left: 5px;
    }

    .pr-mb {
      padding-right: 5px;
    }
  }
}

@media (max-width: 1024px) {
  .edit-profile-details-box,
  .edit-profile-picture-box {
    max-width: 100%;
    width: auto;
  }
}

@media (min-width: 240px) and (max-width: 767px) {
  .user-profile-content-box {
    .user-profile-details-relative {
      height: 100%;
    }

    .user-profile-bg-box {
      height: 164px;

      .dog-profile-bg {
        min-height: 183px;
        height: 100%;
        object-fit: cover;
      }

      .edit-user-profile-bg {
        right: 4px;
        bottom: 8.5px;

        a {
          font-size: 12px;
        }
      }
    }

    .user-profile-other-information-box {
      padding-top: 0;

      .check-grap-btn {
        font-size: 12px;
        margin-bottom: 16px;
        width: 100%;
        height: 45px;
      }

      .ic_warning {
        font-size: 10px;
        margin-bottom: 12px;

        img {
          margin-right: 8px;
        }
      }

      .btn-blue-fill-outline {
        display: block !important;

        .btn-blue-fill {
          font-size: 12px;
          width: 100%;
          margin-bottom: 16.5px;
          height: 45px;
        }

        .btn-blue-outline {
          display: block !important;
          font-size: 12px;
          width: 100%;
          margin-bottom: 20px;
          height: 45px;
        }
      }
    }

    .user-profile-details-relative {
      .user-profile-details-box {
        position: initial;
        height: 100%;
        // top: 0;
        // padding-bottom: 0;
        align-items: center;
        display: block;
        // margin-top: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        // margin-bottom: 16px;
        // border: none;

        &.user-p-details {
          // main profile user name user icon section
          // @media (max-width: 991px) {
          //     margin-left: -15px;
          //     margin-right: -15px;
          // }
        }

        .edit-profile-btn {
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          // margin-bottom: 16px;
          font-size: 10px;
        }

        a.user-profile-edit-btn {
          margin-bottom: 0;
        }

        .media {
          margin-left: 1px;
          position: relative;
          margin-bottom: 16px;

          img:first-child {
            width: 50px;
          }

          .media-body {
            h5 {
              font-size: 16px !important;
              margin-bottom: 2px !important;
              display: flex;
              align-items: center;

              .mark-right {
                svg {
                  width: 13.28px;
                  height: 13.28px;
                }
              }
            }

            p.ic-location {
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 7px;
            }

            p.ic-location svg {
              margin-right: 5px;
              width: 9px;
              height: 12px;
            }
          }

          .notification-mb {
            width: 30.5px;
            height: 30.5px;
            background-color: #326291;
            border-radius: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute !important;
            right: 1px;
            top: 7px;
          }
        }
      }
    }

    .tab-options-box {
      .tab-heading {
        font-size: 14px;
        margin-bottom: 10px;
        width: 100%;
      }

      .select-option-heading {
        font-size: 14px;
        margin-bottom: 10px;
        width: auto;
        display: inline-flex;
      }

      .navbar-nav.navbar-userprofile.navbar-filter-dropdown {
        margin-left: 16px;
        margin-right: 16px;
        flex-direction: inherit;
        display: inline-flex;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        max-width: 170px;

        li {
          font-size: 12px;
          height: 35px;
          margin: 0;
          width: 55%;

          a {
            padding-bottom: 0;
          }

          a:after {
            content: url("/assets/img/ic_up-arrow.png");
            top: 4px;
            margin-top: 3px;
          }
        }
      }

      .form-group {
        margin-bottom: 12px;
      }

      .dog-filter-box {
        display: block;
        margin-bottom: 15px;

        .btn.btn-filter {
          margin: 0 10px 0 0;
          display: inline-block;
          width: 37%;
        }

        .btn-fill-yellow-box {
          width: 58.6%;
          display: inline-block;
        }
      }

      ul.tab-list {
        margin-left: -15px;
        margin-right: 15px;
        width: 108.9%;

        li {
          font-size: 12px;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 9.5px;

          a {
            padding-bottom: 9.5px;
          }
        }
      }

      .dog-details-box {
        .dog-details {
          h4 {
            font-size: 14px;
            margin-right: 5px;
          }

          p {
            font-size: 13px;
          }
        }

        .dog-dropdown {
          .dog-dropdown-menubox {
            ul.nav {
              li {
                a {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }

      .btn.btn-more-dogs {
        display: block;
      }
    }
  }

  // User profile background edit popup css Start
  .edit-profile-picture-box {
    image-cropper {
      min-width: 200px;
    }

    .modal-body {
      h4 {
        font-size: 18px;
        margin-bottom: 18px;
      }

      .form-row {
        margin-left: 0;
        margin-right: 0;
      }

      .edit-user-profile-btn {
        position: relative;
        max-width: 200px;
        width: 100%;
        height: 31px;
        bottom: 0;
        margin-top: 14px;

        .btn {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }

        svg {
          width: 10.49px;
          margin-right: 7.86px;
        }
      }

      .btn-speichern {
        font-size: 14px;
        margin-top: 12px;
        margin-bottom: 12px;
        padding: 7px;
        height: auto;
        width: 100%;
        max-width: 200px;
      }

      .user-edit-profile-d {
        .margin-mb {
          margin-bottom: 13px;
        }

        .form-group {
          padding-left: 0;
          padding-right: 0;
        }

        .drop-down-geschl {
          .ic_down-arrow,
          .ic_calendar,
          .ic_locationmap {
            right: 21px;
          }
        }

        .deine-sprachen-multi-dropdwn {
          padding-right: 0px;
          padding-left: 0px;

          .form-group {
            padding-right: 0px;
            padding-left: 0px;
          }
        }

        .user-profile-edit {
          justify-content: flex-start;

          // img {
          //     width: 75px;
          // }
          .user-edit-camera {
            width: 27px;
            height: 27px;
            right: 30px;
            bottom: 5px;

            svg {
              width: 12px;
            }
          }
        }

        .heading-one,
        .descr-one,
        .descr-two,
        .descr-three {
          font-size: 12px;
          padding-left: 0;
          padding-right: 0;
        }

        .btn-blue-outline {
          margin: 0 auto;
          margin-top: 25px;
        }

        .btn-fill-blue {
          margin-top: 25px;
        }
      }
    }

    .deine-sprachen-multi-dropdwn {
      .form-group {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .cdk-overlay-pane {
    max-width: 100vw !important;
  }

  .cdk-overlay-container .mat-dialog-container {
    border-radius: 0 !important;
  }
}

// @media (min-width:1920px) {
//     .registration-page {
//         section.forgot-password-box,
//         section.login-box,
//         .step-two-form-box {
//             height: calc(100vh - 72px);
//             align-items: center;
//             display: flex;
//             margin-top: 0;
//             margin-bottom: 0;
//         }
//         .step-one-form-box {
//             margin-top: 126px;
//         }
//     }
// }
// .registration-page {
//     section.login-box {
//         @media (min-width: 768px) {
//             height: calc(100vh - 72px);
//             align-items: center;
//             display: flex;
//             margin-top: 0;
//             margin-bottom: 0;
//         }
//     }
// }
.cdk-global-overlay-wrapper {
  pointer-events: auto;
  display: flex;
  position: absolute;
  overflow-y: auto;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    white-space: nowrap;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dedede;
  }
}

.bg-yellow {
  background-color: #fed66b;
}

.bg-light-gray {
  background-color: rgba(192, 192, 192, 0.15);
}

.bg-CEC34D {
  background-color: #cec34d;
}

.bg-E1E9EF {
  background-color: #e1e9ef;
}

.bg-DBE4EB {
  background-color: #dbe4eb;
}

.bg-4CADCD {
  background-color: #4cadcd;
}

.bg-4CADCD-10 {
  background-color: rgb(76 173 205 / 10%);
}

.bg-98B0C8 {
  background-color: #98b0c8;
}

.bg-FFF7E2 {
  background-color: #fff7e2;
}

.bg-FFF7E2-40 {
  background-color: rgba(255, 247, 226, 0.4);
}

.bg-424242-80 {
  background-color: rgba(66, 66, 66, 0.8);
}

.bg-FFF5DA {
  background-color: #fff5da;
}

.bg-C7E5EF {
  background-color: #c7e5ef !important;
}

.bg-green-80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-ff6666 {
  background-color: #ff6666;
}

.cus-px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;

  @media only screen and (max-width: 767px) {
    padding-left: 20px !important;
    padding-right: 16px !important;
  }
}

.popup--dog {
  .modal-body h4 {
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .sub-title {
    font-size: 14px;
    letter-spacing: 0px;
    color: #666666;

    @media only screen and (max-width: 767px) {
      text-decoration: underline;
    }
  }

  .btn {
    border: 1px solid #e6e7e9;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    &.btn-yellow {
      border-right: 0px;
      background-color: #fed66b;
      color: #326291;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.btn-white {
      border-left: 0px;
      color: #666666;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .table-bordered {
    border: 0px;

    th,
    td {
      border: 0px;
      border-right: 1px solid rgba(51, 51, 51, 0.1);
      font-size: 14px;
      font-weight: 500;
      color: #326291;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      padding: 1rem 1.5rem;
      text-align: center;

      @media only screen and (max-width: 991px) {
        font-size: 13px;
        padding: 15px 10px;
      }

      &:last-child {
        border-right: 0px;
      }
    }

    td {
      border-bottom: 1px solid rgba(51, 51, 51, 0.1);
      color: #333;
    }
  }

  .table-hover {
    tbody {
      tr {
        &:hover {
          color: #333333;
          background-color: rgb(102 102 102 / 10%);
        }
      }
    }
  }

  p {
    font-size: 14px;
    color: #333333;

    @media only screen and (max-width: 767px) {
      text-decoration: underline;
    }
  }
}

.info--popup {
  .profile-view {
    width: 120px;
    height: 120px;
    display: block;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  input[type="date"] {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    + .calender-icon {
      position: absolute;
      right: 20px;
      top: 45px;
    }
  }

  .drop-down-geschl {
    .ic_down-arrow {
      top: 55px !important;
    }
  }

  .form-row {
    margin-right: -15px;
    margin-left: -15px;

    .r--dog-c {
      .mat-radio-group {
        display: flex;

        .form-check {
          padding-left: 1.5rem;

          @media only screen and (max-width: 375px) {
            padding-left: 0.5rem;
          }

          label {
            margin-bottom: 0;

            .mat-radio-outer-circle {
              border-color: #c0c0c0;
              border-width: 1px;
            }

            .mat-radio-inner-circle {
              background-color: transparent;
              background-image: url("/assets/img/ic_paw.svg");
              background-repeat: no-repeat;
              background-size: 100%;
            }

            .mat-radio-label-content {
              font-size: 14px;
              color: #797979;
              font-weight: 400;
              font-family: "Poppins", sans-serif;

              @media only screen and (max-width: 375px) {
                font-size: 12px;
              }
            }
          }

          &.mat-radio-checked {
            .mat-radio-outer-circle {
              border: 0px;
            }

            .mat-radio-inner-circle {
              transform: scale(0.8);
              background-color: transparent;
            }
          }
        }
      }
    }

    .r--dog-c1 {
      .mat-radio-group {
        display: flex;

        .form-check {
          padding-left: 0px;
          padding-right: 0.5rem;

          label {
            margin-bottom: 0;

            .mat-radio-container {
              display: none;
            }

            .mat-radio-label-content {
              padding: 10px;
              color: #666;
              border-radius: 10px;
              border: 1px solid #c0c0c0;
              width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &.mat-radio-checked {
            .mat-radio-label-content {
              border-color: #fed66b;
              background-color: #fed66b;
              color: #326291;
            }
          }
        }
      }

      &.for-mobile-size {
        @media only screen and (max-width: 767px) {
          flex-wrap: wrap;
        }

        .ms {
          margin-bottom: 0.9rem;

          @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 1rem !important;
          }
        }

        .form-check {
          @media only screen and (max-width: 767px) {
            padding-left: 0rem;
            padding-right: 0.5rem;
          }
        }
      }
    }

    input {
      //color: rgb(102, 102, 102, 0.80) !important;
      &::placeholder {
        color: rgb(102, 102, 102, 0.8) !important;
      }
    }
  }

  p {
    font-size: 14px;
    color: #333333;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
}

.file-upload-how {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .file-upload {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    input[type="file"] {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.fs-52 {
  font-size: 52px;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.fs-12 {
  font-size: 12px;
}

.cus-xl-mb-30 {
  @media (max-width: 1199px) {
    margin-bottom: 30px;
  }
}

.fsm-12 {
  font-size: 12px;

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

.fs-16 {
  font-size: 16px;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

// .fs-36 { font-size: 36px; }
// .fs-36-imp { font-size: 36px !important; }
// .fs-32 { font-size: 32px; }
// .fs-28 { font-size: 28px; }
// .fs-28-imp { font-size: 28px !important; }
// .fs-26 { font-size: 26px; }
// .fs-24 { font-size: 24px; }
// .fs-22 { font-size: 22px; }
// .fs-22-imp { font-size: 22px !important; }
// .fs-21 { font-size: 21px; }
// .fs-21-imp { font-size: 21px !important; }
// .fs-20 { font-size: 20px; }
// .fs-20-imp { font-size: 20px !important; }
// .fs-18 { font-size: 18px; }
// .fs-18-imp { font-size: 18px !important; }
// .fs-16-imp { font-size: 16px !important; }

.fsm-22 {
  font-size: 22px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.fs-11 {
  font-size: 11px;
}

.fsm-18 {
  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.fsm-16 {
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.fsm-14 {
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.font-weight-res.font-weight-sm-med {
  @media only screen and (max-width: 767px) {
    font-weight: 500 !important;
  }
}

.font-pm {
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
}

.font-pr {
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
}

.font-ps {
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.lh-inherit {
  line-height: inherit;
}

.lh-1 {
  line-height: 1;
}

.lh-initial {
  line-height: initial;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 78px;
}

.btt-btn {
  bottom: 20px;
  right: 30px;
  z-index: 9999;

  @media (max-width: 767px) {
    bottom: 15px;
    right: 15px;
  }
}

.font-cb {
  font-family: "Constantia";
  font-weight: bold;
}

.color-b--80 {
  color: rgba(0, 0, 0, 0.8);
}

.color-b--60 {
  color: rgba(0, 0, 0, 0.6);
}

.color-33 {
  color: #333333;
}

.color-blue {
  color: #326291;
}

.text-underline-cus {
  text-decoration: underline;
}

.white-space-break {
  white-space: break-spaces;
}

.no-text-wrap { white-space: nowrap; }

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.color-blue-light {
  color: #4cadcd;
}

.color-gray {
  color: #797979;
}

.color-dark-gray {
  color: #424242 !important;
}

.color-bluelight {
  color: #4cadcd;
}

.color-dgray {
  color: #424242;
}

.color-dgray-70 {
  color: rgba(66, 66, 66, 0.7) !important;
}

.color-b4 {
  color: #b4b4b4;
}

.color-666 {
  color: #666666;
}

.color-CEC34D {
  color: #cec34d !important;
}

.color-669966 {
  color: #669966;
}

.color-525252 {
  color: #525252;
}

.color-326291-80 {
  color: rgba(50, 98, 145, 0.8);
}

.border-color-l-gray {
  border-color: #e0e0e0;
}

.border-color-l-gray-bottom {
  border-bottom: 1px solid rgba(192, 192, 192, 0.3);
}

.border-radius-round {
  border-radius: 3rem;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-4 {
  border-radius: 4px;
}

.box-shadow-chat {
  box-shadow: 1px 2px 6px rgba(33, 43, 51, 0.102) !important;
}

.cus-strikethrough-blue {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.cus-strikethrough-blue:after {
  position: absolute;
  content: "";
  width: 105%;
  height: 2px;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-20deg);
  background-color: #52bbd5;
  border-radius: 50px;
  z-index: 1;
}

.cus-strikethrough-red {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.cus-strikethrough-red:after {
  position: absolute;
  content: "";
  width: 105%;
  height: 2px;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-20deg);
  background-color: #ff6666;
  border-radius: 50px;
  z-index: 1;
}

.min-height-29 {
  @media (max-width: 359px) {
    min-height: 29px;
  }
}

.themecheck-box {
  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 30px;
      height: 20px;
      background: white;
      border-radius: 4px;
    }

    // Box hover
    &:hover + label:before {
      background: #326291;
    }

    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      background: #326291;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
}

.form-theme-dog {
  input {
    border-color: #d8d8d8;
    height: 50px;
    color: #98b0c8;
    font-size: 16px;
  }
}

a {
  cursor: pointer;
}

.error-label {
  color: #ff6666 !important;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px !important;
}

.valid-error {
  border-color: #ff6666 !important;
}

.mat-tab-body-content {
  height: auto !important;
  overflow: initial !important;
}

.pet-dropdown.header-dropdown-pet {
  &.mat-menu-panel {
    max-height: 300px;
    min-height: auto;
  }
}

.mat-menu-panel {
  .mat-menu-content {
    button {
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #000;
      opacity: 0.6;

      &:hover {
        opacity: 1;
        color: #326291;
      }

      &.filter-name {
        line-height: 30px !important;
        height: auto !important;
      }
    }
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: transparent;
}

.checkbox--dog {
  &.md-check {
    display: flex;
    flex-wrap: wrap;

    .info-text {
      min-height: 24px;
      padding: 0;
      line-height: 24px;
      display: inline-block;
      margin-left: 2px;
    }

    .mat-checkbox {
      margin-bottom: 20px;

      .mat-checkbox-frame {
        transform: scale(1.5) translateX(2px);
      }

      .mat-checkbox-label {
        margin-left: 10px;
        font-weight: 400;
        color: #525252;
      }
    }

    .mat-checkbox-checked {
      .mat-checkbox-background {
        transform: scale(1.5) translateX(2px);
      }
    }
  }

  label {
    margin-bottom: 0px;
  }

  .mat-checkbox-frame {
    border-width: 1px;
    border-color: #c0c0c0;
    background-color: #fff;
  }

  .mat-checkbox-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    // white-space: normal;

    @media only screen and (max-width: 375px) {
      font-size: 12px;
    }
  }

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 4px;
  }

  .mat-checkbox-indeterminate,
  .mat-checkbox-checked {
    .mat-checkbox-frame {
      border: 0px;
      background-color: transparent;
    }

    .mat-checkbox-background {
      background-color: transparent;
      background-image: url("/assets/img/ic_paw.svg");
      background-repeat: no-repeat;
      background-size: 100%;

      svg {
        opacity: 0;
      }
    }

    .mat-checkbox-label {
      color: #000;
    }
  }
}

.toggle-btn--dog {
  .btn {
    border: 1px solid #e6e7e9;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    padding: 0px;
    width: 36px;
    height: 30px;
    display: flex;

    &.mat-button-toggle-checked {
      background-color: #fed66b;
      border-color: #fed66b;
      color: #326291;
    }

    &.btn-first {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 0px;
    }

    &.btn-second {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: 0px;
    }

    .mat-button-toggle-label-content {
      line-height: inherit;
      padding: 0px;
      display: block;
    }
  }
}

.geschlecht-drop {
  .ng-select {
    .ng-select-container {
      height: 45px !important;
      font-size: 14px;
      color: #000;
    }
  }
}

.switich-tabs {
  hr {
    margin-top: 28px;
    margin-bottom: 28px;
  }
}

.font-weight-sbold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.fw-medium-sm {
  @media (max-width: 767px) {
    font-weight: 500 !important;
  }
}

.select-ap--control {
  .mat-form-field-wrapper {
    padding-bottom: 0px;

    .mat-form-field-infix {
      margin-top: 0px;
      border: 0px;

      .mat-select {
        border-radius: 8px;
        border: solid 1px #e6e7e9;
        color: #326291;
        font-size: 14px;
        height: 45px;
        font-family: "Poppins", sans-serif;
      }

      .mat-select-trigger {
        height: 100%;

        .mat-select-value {
          color: #333333;
          line-height: 45px;
          padding-left: 1rem;
          font-size: 13px;
          font-weight: 500;
          text-transform: capitalize;
          font-family: "Poppins", sans-serif;
        }
      }

      .mat-select-arrow-wrapper {
        padding: 10px;

        .mat-select-arrow {
          border: solid #326291;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.range-slider {
  .ngx-slider {
    margin: 10px 0 15px;

    .ngx-slider-pointer {
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      top: -6px;

      &:after {
        width: 12px;
        height: 12px;
        background-color: #326291;
        top: 2px;
        left: 2px;
        border-radius: 100%;
      }

      &.ngx-slider-active {
        &:after {
          background-color: #326291;
        }
      }

      &:hover {
        &:after {
          background-color: #326291;
        }
      }
    }

    .ngx-slider-bar {
      height: 6px;
      background: #e6e7e9;

      &.ngx-slider-selection {
        background: #326291;
      }
    }

    .ngx-slider-bubble {
      bottom: -38px;
      padding: 5px 3px;
      color: #333333;
      font-size: 14px;
      font-weight: 500;

      &.ngx-slider-limit {
        color: #333333;
      }
    }
  }

  .ngx-slider-ceil {
    right: 0px !important;
    left: auto !important;
  }
}

.multiple-se {
  .mat-checkbox {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0px;
    }

    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        display: none;
      }

      .mat-checkbox-label {
        padding: 10px;
        color: #666;
        border-radius: 10px;
        border: 1px solid #c0c0c0;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.mat-checkbox-checked {
      .mat-checkbox-layout {
        .mat-checkbox-label {
          border: 1px solid #fed66b;
          background-color: #fed66b;
          color: #326291;
        }
      }
    }
  }

  &.label-btn {
    .mat-checkbox {
      margin-right: 0;
      width: 100%;

      .mat-checkbox-layout {
        display: block;

        .mat-checkbox-label {
          width: 100%;
        }
      }
    }
  }
}

.mat-select-panel-wrap {
  flex-basis: 80% !important;
  transform: translateX(41px) translateY(46px);

  @media only screen and (max-width: 767px) {
    transform: translateX(26px) translateY(46px);
  }

  .mat-select-panel {
    padding: 10px 20px;

    .mat-option {
      background-color: transparent !important;
      color: rgba(0, 0, 0, 0.6);
      border-bottom: 1px solid #d8d8d8;
      padding: 0px;
      font-family: "Poppins", sans-serif;

      &:last-child {
        border-bottom: 0px;
      }

      .mat-pseudo-checkbox {
        order: 2;
        border: 0px !important;
        background-color: transparent !important;

        &:after {
          color: #326291 !important;
          top: 4.4px !important;
          width: 12px !important;
          height: 5px !important;
          border-left: 1px solid currentColor !important;
          border-bottom: 1px solid currentColor !important;
        }
      }
    }
  }
}

.mat-tab-group {
  font-family: "Poppins", sans-serif;

  .mat-tab-label-container {
    overflow: visible;
  }

  .mat-tab-list {
    .mat-tab-label {
      color: #797979;
      opacity: 1;
      padding: 0px;
      min-width: auto;
      margin-right: 1rem;
      font-family: "Poppins", sans-serif;

      &:last-child {
        margin-right: 0rem;
      }

      &.mat-tab-label-active {
        color: #326291;
      }
    }
  }

  &.mat-primary {
    .mat-ink-bar {
      bottom: -3px;
      height: 3px;
      z-index: 99999;
      background-color: #326291;
    }
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    overflow: visible;
    border-bottom: 3px solid #d8d8d8;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option span {
      font-size: 12px;
      color: #000;
    }
  }
}

.pet-dropdown {
  .mat-menu-content {
    button {
      align-items: center;
      line-height: initial;
      height: auto;
      margin-bottom: 0.5rem;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      padding: 0px 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      .img---v {
        width: 25px;
        height: 25px;
        overflow: hidden;
        display: block;
        border-radius: 100%;
        margin-right: 0.5rem;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      span {
        text-transform: capitalize;
      }
    }
  }
}

#app-row-header {
  background: rgb(255, 255, 255);
  position: fixed;
  right: 0px;
  left: 0px;
  width: 100%;
  z-index: 11;
  top: 0;

  &.sticky-global {
    background-color: rgba(255, 255, 255, 0.9);

    .navbar {
      .navbar-brand {
        img {
          // width: 100px;
        }
      }

      .navbar-nav {
        .nav-link,
        .dropdown,
        .paw-notification {
          // transform: scale(0.9);
        }
      }
    }
  }
}

.profile-dog-header-image {
  .user-profile-bg-box {
    border-radius: 0;
  }
}

.nav-menu-profile-container {
  .nav-menu-profile {
    background-color: #fed66b;
    border-radius: 0 0 6px 6px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      li {
        margin-right: 1rem;

        @media screen and (min-width: 1024px) {
          margin-right: 3rem;
        }

        &:first-child {
          @media screen and (max-width: 767px) {
            margin-left: 1rem;
          }
        }

        a {
          color: rgba(0, 0, 0, 0.4);
          font-size: 16px;
          font-weight: 500;
          padding: 16px 0;

          @media screen and (max-width: 767px) {
            padding: 10px 0;
            font-size: 13px;
          }

          display: inline-block;

          &.active,
          &:hover {
            color: #326291;
            border-bottom: 2px solid #326291;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.tab-page {
  padding-top: 40px;

  @media screen and (max-width: 1024px) {
    padding-top: 20px;
  }
}

.profile-actions {
  @media screen and (max-width: 767px) {
    text-align: center;
  }

  .theme-secondary-btn {
    @media screen and (max-width: 767px) {
      min-width: 220px;
    }
  }
}

.dog-details-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  padding: 20px;
  margin-bottom: 25px;

  @media screen and (max-width: 767px) {
    border-radius: 8px;
    padding: 10px;
  }

  .information {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 15px;
        color: #525252;
        margin-bottom: 20px;
        position: relative;
        padding-left: 30px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }

        &:after {
          content: "";
          height: 20px;
          width: 20px;
          position: absolute;
          left: 0;
          top: 0px;
          background-image: url("/assets/img/ic_paw.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .age {
    p {
      font-size: 14px;
      color: #326291;
      margin: 0;
      text-align: right;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .birthday-card {
    background-color: #fed66b;
    border-radius: 9px;
    padding: 12px;
    margin-top: 20px;

    @media screen and (max-width: 767px) {
      border-radius: 3px;
      padding: 5px;
    }

    .date-card {
      display: flex;
      align-items: center;

      .icon {
        height: 42px;
        width: 42px;
        margin-right: 10px;

        @media screen and (max-width: 767px) {
          height: 35px;
          width: 35px;
          margin-right: 5px;
        }
      }

      .title-details {
        width: calc(100% - 52px);

        h4 {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          color: #326291;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          font-size: 14px;
          color: #326291;
        }
      }
    }
  }

  .dog-name-details {
    display: flex;
    align-items: center;

    .name-details {
      width: calc(100% - 70px);

      h4 {
        margin: 0;
        color: #326291;
        font-size: 18px;

        @media screen and (max-width: 1024px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin: 0;
      }
    }

    .avatar-container {
      position: relative;
      margin-right: 15px;

      .avatar {
        height: 55px;
        width: 55px;
        overflow: hidden;
        border-radius: 100px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .change-image {
        height: 22px;
        width: 22px;
        border: 1px solid #fff;
        border-radius: 100px;
        background-color: #326291;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px;

        img {
          width: 100%;
        }
      }
    }
  }
}

.dog-description {
  border-top: 1px solid #d8d8d8;

  .content {
    p {
      color: #525252;
      font-size: 14px;
      line-height: 22px;
      //word-break: break-all;
    }
  }

  .title {
    display: flex;
    margin-top: 15px;

    h3 {
      color: #326291;
      font-size: 18px;
      margin: 0;
    }

    .edit {
      background-color: transparent;
      border: none;
      margin-left: 5px;
    }

    .btn-edit-yellow {
      background-color: #fed66b;
      border-radius: 5px;
      width: 26px;
      height: 27px;
      margin: 0px;
      display: block;
      padding: 0;
    }
  }

  .video-section {
    .video-view {
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-top: 15px;
    }
  }
}

.profile-card {
  border-radius: 10px;
  margin-bottom: 25px;
  box-shadow: 1px 1px 6px rgba(33, 43, 51, 0.1);
  border: none;

  .card-body {
    .chip-number {
      font-size: 22px;
      font-weight: 500;
      color: #326291;
    }
  }

  .card-header {
    border-radius: 10px 10px 0 0;
    background-color: #e0e0e0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.no-actions {
      .title {
        width: 100%;
      }
    }

    .actions {
      min-width: 80px;
      display: flex;
      justify-content: flex-end;

      .action-btn {
        background-color: #fed66b;
        height: 27px;
        width: 27px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 5px;
        padding: 0;
        margin-left: 10px;

        img {
          height: 12px;
          width: 12px;
        }
      }
    }

    .title {
      display: flex;
      white-space: nowrap;
      overflow: hidden;

      .name {
        font-size: 18px;
        font-weight: 600;
        color: #326291;
        width: calc(100% - 25px);
        white-space: pre-wrap;
        display: flex;
        align-items: center;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      .icon {
        height: 25px;
        width: 25px;
        margin-right: 10px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.profile-card {
  .info {
    color: #525252;
  }

  .document-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        .document-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid rgba(50, 98, 145, 0.5);
          padding: 10px 20px;
          margin-bottom: 20px;
          border-radius: 8px;

          .actions {
            .download-btn {
              background-color: transparent;
              border: none;
              padding: 0;
            }
          }

          .details {
            display: flex;
            align-items: center;

            .name {
              h3 {
                color: #326291;
                font-size: 16px;
                font-weight: 500;
                margin: 0;
                margin-bottom: 5px;
              }

              p {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
                margin: 0;
              }
            }

            .icon {
              height: 22px;
              width: 22px;
              margin-right: 10px;

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}

.link-btn {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #326291;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.theme-primary-btn {
  background-color: #fed66b;
  border-radius: 3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #326291;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 30px;
  border: none;
  min-width: 200px;

  &:disabled {
    opacity: 0.6;
  }

  img.icon-lg {
    width: 25px;
    margin-right: 15px;

    @media (max-width: #{$breakpoint-sm}px) {
      width: 20px;
      margin-right: 10px;
    }
  }

  .icon {
    height: 14px;
    width: 14px;
    margin-right: 10px;
    line-height: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: #{$breakpoint-sm}px) {
    font-size: 14px;
  }
}

.theme-secondary-btn {
  background-color: #326291;
  border-radius: 3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 30px;
  border: none;
  min-width: 200px;

  .icon {
    height: 14px;
    width: 14px;
    margin-right: 10px;
    line-height: 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    color: #fff;
  }
}

.btn-border-blue {
  background-color: transparent;
  border-radius: 3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #326291;
  border: 1px solid #326291;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 10px 30px;
  min-width: 200px;
}

.theme-border-btn {
  border: 1px solid #326291;
  border-radius: 3em;
  color: #326291;
  min-width: 200px;
}

.white-btn-border {
  background: transparent;
  border: 1px solid #326291;
  color: #326291;
  width: 270px;

  @media (max-width: 1200px) {
    width: 250px;
  }

  &:hover {
    color: #fff;
    background-color: #326291;
  }
}

.btn-width-270 {
  width: 270px;

  @media (max-width: 1200px) {
    width: 250px;
  }
}

.btn-xl-width-290 {
  @media (min-width: 1921px) {
    width: 280px;
    max-width: 280px;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.min-width-165-xl {
  @media (min-width: 1921px) {
    width: 165px;
  }
}

.btn-hover-blue {
  &:hover {
    color: #326291;
  }
}

app-description-dialog {
  .custom-modal {
    width: 80vw;
    max-width: 600px;

    @media screen and (max-width: 767px) {
      width: 100vw;
      height: 100vh;
      overflow: auto;
      max-width: 100%;
    }

    .custom-modal-body {
      padding: 20px;
    }

    .cutsom-modal-header {
      position: relative;

      h4 {
        font-size: 24px;
        color: #326291;
        padding-top: 40px;
        text-align: center;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          padding-top: 50px;
        }
      }

      .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        z-index: 999;
      }
    }
  }
}

app-docs-dialog {
  .custom-modal {
    width: 80vw;
    max-width: 756px;

    @media screen and (max-width: 767px) {
      width: 100vw;
      height: 100%;
      max-width: 100%;
      overflow: auto;
    }

    .custom-modal-body {
      padding: 0px;

      .select-form {
        margin: 20px;
      }

      .upload-section {
        margin: 20px;
        border-bottom: 1px solid #ced0d4;
        padding-bottom: 20px;

        h3 {
          color: #333333;
          font-size: 14px;
          margin: 0;
          margin-bottom: 10px;
        }

        .actions {
          .theme-secondary-btn {
            .icon {
              height: 20px;
              width: 20px;
            }
          }

          > span {
            font-size: 14px;
            color: #666666;
            margin-left: 20px;

            @media screen and (max-width: 767px) {
              display: block;
              margin-left: 0;
              padding: 20px 0 0;
            }
          }
        }
      }
    }

    .cutsom-modal-header {
      position: relative;

      h4 {
        font-size: 24px;
        color: #326291;
        padding-top: 40px;
        text-align: center;
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          padding-top: 50px;
        }
      }

      .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        z-index: 999;
      }
    }
  }
}

.table-responsive {
  .table {
    thead {
      th {
        background-color: #fed66b;
        border-top: 0;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 0;
        color: #326291;
        padding: 14px;
        white-space: nowrap;
      }
    }

    tbody {
      td {
        border: 0;
        font-size: 14px;
        color: #333333;
        padding: 14px;
        vertical-align: middle;
      }
    }
  }
}

.mat-dialog-container {
  border-radius: 10px !important;
}

a.text-link {
  color: #336291;
  text-decoration: underline;
  background-color: transparent;
}

.info-text {
  color: #525252;
}

.invisible {
  visibility: hidden;
}

.hidden-text-active {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cdk-global-overlay-wrapper {
  @media screen and (max-width: 1024px) {
    // align-items: unset !important;
  }
}

.c--img-upload {
  .edit-profile-picture-box {
    .modal-body {
      .edit-user-profile-btn {
        position: relative;
        bottom: 0;
        margin-top: 1.5rem;
      }
    }
  }
}

.ng-select.ng-select-single {
  .ng-select-container {
    .ng-value-container,
    .ng-value {
      // max-width: 200px;
    }
  }
}

.multiple-select-view {
  .ng-select {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          display: flex;

          .ng-value-label {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 18px;
            display: block;
          }
        }

        input {
          height: auto;
        }

        .ng-placeholder {
          top: 12px;
        }
      }

      height: auto !important;
      min-height: 50px;

      .ng-arrow-wrapper {
        display: block;
        padding-right: 10px;

        .ng-arrow {
          border: solid #326291;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          padding: 4.5px;
        }
      }
    }
  }
}

.custom-responsive-table {
  overflow: auto;

  .inner-table {
    min-width: 756px;

    .table-body {
      .table-row {
        display: flex;
        width: 100%;

        .table-item {
          border: 0;
          font-size: 14px;
          color: #333333;
          padding: 14px;
          width: 20%;
        }
      }
    }

    .table-header {
      .table-row {
        display: flex;
        width: 100%;

        .table-item {
          width: 20%;
          background-color: #fed66b;
          border-top: 0;
          font-size: 16px;
          font-weight: 500;
          border-bottom: 0;
          color: #326291;
          padding: 14px;
          white-space: nowrap;
        }
      }
    }
  }
}

.table-row {
  display: flex;
  width: 100%;

  .table-item {
    border: 0;
    font-size: 14px;
    color: #333333;
    padding: 14px;
    width: 20%;
    background-color: #fff;
  }
}

.example-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cursr-pointr {
  cursor: pointer;
}

.custom-responsive-table .inner-table .table-body,
.custom-responsive-table .inner-table .table-header {
  .table-row-container {
    background-color: #fff;
  }

  .table-row {
    .table-item {
      &:nth-child(1) {
        width: 14%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 20%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(5) {
        width: 26%;
      }
    }
  }
}

.table-row {
  .table-item {
    &:nth-child(1) {
      width: 14%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 20%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(5) {
      width: 26%;
    }
  }
}

.table-row-container {
  background-color: #fff;
}

.registration-page {
  section {
    form {
      .ng-placeholder {
        color: #797979 !important;
      }

      .ng-select {
        span {
          color: #98b0c8 !important;
        }
      }

      .ng-dropdown-panel-items {
        .ng-option {
          span {
            color: #98b0c8 !important;
          }
        }
      }
    }
  }
}

.theme-yellow-btn {
  background-color: #fed66b;
  border-radius: 3em;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #326291;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  max-width: 200px;

  &:hover {
    text-decoration: none;
  }
}

.btn-theme-gray {
  background-color: #e0e0e0;
  border-radius: 3em;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #797979;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  max-width: 200px;

  &:hover {
    text-decoration: none;
  }
}

.btn-theme-blue {
  background-color: #326291;
  border-radius: 3em;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  max-width: 200px;

  &:hover {
    text-decoration: none;
  }
}

.mat-accordion {
  margin-bottom: 1.5rem;
  display: block;

  @media screen and (max-width: 767px) {
    margin-bottom: 0.5rem;
  }

  .mat-expansion-panel {
    box-shadow: 0px 0px 15px rgba(33, 43, 51, 0.08);

    @media screen and (max-width: 767px) {
      box-shadow: none;
    }

    &:first-of-type {
      border-top-right-radius: 10px !important;
      border-top-left-radius: 10px !important;
    }

    &:last-of-type {
      border-bottom-right-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }

    .mat-expansion-panel-header {
      background-color: #fed66b;
      color: #326291;
      height: 50px;
      padding-left: 3rem;
      padding-right: 4rem;
      font-family: "Poppins", sans-serif;
      transition: all 0.3s ease;

      @media screen and (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 50px;
      }

      &:hover {
        background-color: #e4e6eb;
      }

      span {
        .mat-expansion-panel-header-title {
          color: #326291;
          font-weight: 600;
          font-size: 15px;
          font-family: "Poppins", sans-serif;
        }
      }

      &.mat-expanded {
        height: 50px;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        @media screen and (max-width: 767px) {
          height: 50px;
        }

        &:hover,
        &:focus {
          background-color: #fed66b !important;
        }
      }

      .mat-expansion-indicator {
        &:after {
          color: #326291;
          padding: 4px;
          margin-top: -4px;
        }
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 20px;
        background-color: #fff;
        color: #797979;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }

        .mat-accordion {
          border: 1px solid #e4e6eb;
          border-radius: 0px;
          margin-bottom: 1rem;

          @media screen and (max-width: 767px) {
            border-radius: 0px;
            margin-bottom: 5px;
          }

          .mat-expansion-panel {
            box-shadow: none;
            border-radius: 0px !important;

            .mat-expansion-panel-header {
              background-color: #e4e6eb;
              border-radius: 0;
              height: 50px;
              padding-left: 2rem;
              padding-right: 2rem;

              @media screen and (max-width: 767px) {
                height: 50px;
                padding-left: 1rem;
                padding-right: 1rem;
              }

              span {
                .mat-expansion-panel-header-title {
                  color: #333;
                  font-weight: 500;
                  font-size: 15px;
                }
              }

              &.mat-expanded {
                &:hover,
                &:focus {
                  background-color: #e4e6eb !important;
                }
              }

              .mat-expansion-indicator {
                &:after {
                  color: #326291;
                }
              }
            }

            .mat-expansion-panel-content {
              .mat-expansion-panel-body {
                padding: 2rem 3rem;

                @media screen and (max-width: 767px) {
                  padding: 1rem;
                }

                p {
                  font-size: 14px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.6);
                  font-family: "Poppins", sans-serif;
                }
              }
            }
          }

          &.inner-undesign--j {
            border: 0px;
            border-radius: 0px;

            .mat-expansion-panel {
              border-bottom: 1px solid #e4e6eb;
              border-radius: 0px !important;
              padding-bottom: 1rem;
              padding-right: 3px;
              margin-bottom: 1rem;

              .mat-expansion-panel-header {
                background-color: transparent;
                padding-left: 0rem;
                padding-right: 0rem;
                height: auto;

                &.mat-expanded {
                  &:hover,
                  &:focus {
                    background-color: transparent !important;
                  }
                }
              }

              .mat-expansion-panel-content {
                .mat-expansion-panel-body {
                  padding-right: 0px;
                  padding-bottom: 1rem;

                  ul {
                    li {
                      border-bottom: 1px solid #e4e6eb;
                      padding: 1rem 0px;

                      &:first-child {
                        padding-top: 0px;
                      }

                      &:last-child {
                        border-bottom: 0px;
                      }
                    }
                  }
                }
              }
            }

            .color-b--60 {
              color: rgba(0, 0, 0, 0.6) !important;
            }
          }
        }
      }
    }
  }
}

.default-select-theme {
  .ng-select-container {
    .ng-value-container {
      max-width: 100% !important;

      .ng-input {
        height: 50px;
        top: 0 !important;

        input {
          height: 50px;
        }
      }
    }

    .ng-arrow-wrapper {
      display: block;
      padding-right: 35px;

      .ng-arrow {
        border: solid #326291;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        padding: 4.5px;
      }
    }
  }
}

.dog-add {
  @media screen and (min-width: 992px) {
    right: 15px !important;
  }
}

.form-control[readonly] {
  background-color: #fff;
}

.ng-select {
  .ng-select-container {
    border: solid 1px #e6e7e9;

    .ng-value-label,
    .ng-placeholder {
      font-size: 14px;
      color: rgba(102, 102, 102, 0.8);

      @media screen and (min-width: 375px) {
        font-size: 12px;
      }
    }
  }

  .ng-arrow-wrapper {
    padding-right: 20px;

    .ng-arrow {
      border: solid #326291;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      padding: 4.5px;
    }
  }

  &.ng-select-opened {
    > {
      .ng-select-container {
        .ng-arrow {
          top: 0;
          border: 0;
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 0px;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.profil-bearbeiten {
  .modal-body {
    .user-edit-profile-d {
      .form-group {
        input {
          color: #326291;

          &#address {
            @media screen and (max-width: 991px) {
              white-space: nowrap;
              padding-right: 4rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .ng-select {
        .ng-select-container {
          .ng-value-label {
            color: #326291;
          }
        }
      }
    }
  }
}

.app-profile-dogs-dog-detail-dog-gallery,
.gallery-popup {
  .three-view-show {
    margin: 0px -1px;

    .col--31-change {
      padding: 0px 1px;
      margin-bottom: 2px;

      .dog-details-box {
        img {
          border-radius: 4px;
          height: 85px;
          object-fit: cover;
          cursor: pointer;

          @media screen and (max-width: 1024px) {
            height: 70px;
          }

          @media screen and (max-width: 991px) {
            height: 160px;
          }

          @media screen and (max-width: 767px) {
            height: 100%;
          }
        }
      }
    }
  }
}

.gallery-popup {
  .modal-body {
    padding: 40px 40px 30px 40px;

    @media screen and (max-width: 767px) {
      padding: 70px 20px 30px 20px;
    }

    .three-view-show {
      margin: 0px -5px;

      .col-md-3,
      .col-6 {
        padding: 0px 5px;
        margin-bottom: 10px;

        .dog-details-box {
          position: relative;

          img {
            border-radius: 10px;
          }

          .list-view-img {
            position: relative;
            cursor: pointer;

            img {
              height: 161px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

/*toggle  dropdown*/

.btn-toggle-custom {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 99;
}

.dog-gallery-drop {
  background-color: rgba(50, 98, 145, 0.8);

  .mat-menu-content {
    button {
      opacity: 1;
      line-height: normal;
      color: #fff;
      height: auto;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0.5rem;
      padding-left: 10px;

      &:last-child {
        margin-bottom: 0px;
      }

      &:hover {
        background-color: transparent;
        color: #fff;
      }

      &.submenu-view {
        &:after {
          border-color: #fff;
          border-width: 0 2px 2px 0;
          top: 24%;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
        }

        &.mat-menu-item-highlighted {
          &:after {
            top: 39%;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

/*toggle  dropdown*/

.slider-gallery-popup {
  .ngx-gallery {
    margin-bottom: 0px !important;
  }

  .ngx-gallery-thumbnails-wrapper {
    .ngx-gallery-thumbnails {
      .ngx-gallery-thumbnail {
        border: 0px !important;

        .ngx-gallery-thumbnail {
          border: 0px !important;
        }
      }
    }
  }

  ngx-gallery-thumbnails {
    .ngx-gallery-arrows-wrapper {
      .ngx-gallery-icon {
        transform: unset;
        top: 0px;
        height: 100%;
        width: 30px;
        background-image: url("/assets/img/left-gallery.svg");
        display: flex;
        align-items: center;
        justify-content: center;

        .ngx-gallery-icon-content {
          color: #326291;
        }

        .ngx-gallery-icon {
          color: #326291;
        }
      }

      &.ngx-gallery-arrow-left {
        .ngx-gallery-icon {
          left: 0px;
        }
      }

      &.ngx-gallery-arrow-right {
        .ngx-gallery-icon {
          right: 0px;
          background-image: url("/assets/img/right-gallery.svg");
        }
      }
    }
  }

  .mySwiper2 {
    max-width: 985px;
    margin: 0 auto;
    height: calc(100vh - 340px);

    @media screen and (min-width: 2560px) {
      height: calc(100vh - 350px);
    }

    @media screen and (max-width: 767px) {
      height: calc(100vh - 190px);
    }

    .swiper-slide {
      display: flex;
      align-items: center;

      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
        margin: auto;
      }
    }
  }

  .mySwiper {
    margin: 10px 0px 20px 0;
    height: 20vh;

    @media screen and (max-width: 1024px) {
      height: 10vh;
    }

    @media screen and (max-width: 767px) {
      height: 8vh;
    }

    @media screen and (min-width: 1200px) {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      opacity: 0.4;

      // background-color: rgba(255, 255, 255, 0.2);
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
        margin: auto;
        width: 100%;
        height: 100%;
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    .swiper-button-prev {
      background-image: url("/assets/img/bg-left.svg");
      height: 100%;
      top: 22px;
      width: 50px;
      background-repeat: no-repeat;
      left: 0;
      text-align: center;
      background-size: cover;

      &:after {
        font-size: 20px;
      }
    }

    .swiper-button-next {
      background-image: url("/assets/img/bg-right.svg");
      height: 100%;
      top: 22px;
      width: 50px;
      background-repeat: no-repeat;
      right: 0;
      text-align: center;
      background-size: cover;

      &:after {
        font-size: 20px;
      }
    }
  }
}

.full-screen-popup {
  max-width: 100% !important;
  width: 100%;

  .mat-dialog-container {
    border-radius: 0px !important;
    background: rgba(66, 66, 66, 0.9);
    height: 100vh;

    .edit-profile-picture-box {
      width: 100%;
    }
  }
}

.chat-box {
  .header {
    border-bottom: 1px solid #e6e7e9;
  }

  .img-profile {
    margin-right: 10px;

    .img-view--ch {
      width: 46px;
      height: 46px;
      border-radius: 100%;
      display: block;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .chat-details {
    // width: 100%;
    .box-view {
      background-color: rgba(192, 208, 223, 0.6);
      border-radius: 10px;
      padding: 10px;

      h3 {
        font-size: 13px;
        font-weight: 600;
        color: #333333;
      }

      p {
        color: #424242;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
    }

    span {
      color: #797979;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .chat-bottom {
    border-top: 1px solid #e6e7e9;
    position: absolute;
    bottom: 0;
  }
}

.location-dd {
  max-width: 12px !important;
  min-width: 12px !important;

  img {
    margin-bottom: 4px;
  }
}

.location-dd-wrap {
  line-height: 38px !important;
  font-size: 14px;
  padding-left: 10px !important;
}

.map-modal {
  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 767px) {
    width: 320px;
  }

  .modal-body {
    h4 {
      @media (max-width: 767px) {
        text-align: left;
      }
    }
  }

  .search-location {
    display: flex;
    border: 1px solid #f4f4f4;
    box-shadow: 0 1px 2px rgb(0 0 0 / 21%);
    border-radius: 4px;
    align-items: center;
    max-width: 500px;
    padding: 10px;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    width: 100%;
    top: 1.5rem;
    left: 2rem;
    justify-content: space-between;

    @media (max-width: 991px) {
      top: 1rem;
      left: 1rem;
    }

    @media (max-width: 575px) {
      max-width: 260px;
      margin: 0 auto;
      left: 0px;
      right: 0px;
    }

    input {
      border: 0px;
      max-width: 250px;
      padding: 5px;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::-webkit-input-placeholder {
        color: #98b0c8;
      }

      &:-ms-input-placeholder {
        color: #98b0c8;
      }

      &::placeholder {
        color: #98b0c8;
      }
    }

    span {
      color: #326291;
      font-size: 14px;
    }
  }

  agm-map {
    height: 420px;
  }
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  .gmnoprint {
    transform: rotate(90deg);
    right: 0px;

    > div {
      border-radius: 4px !important;
      border: 1px solid #f4f4f4;

      button {
        img {
          opacity: 0.3;
        }

        + div {
          display: none;

          + button {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

.notifiction-drop {
  max-width: 480px !important;
  border-radius: 10px !important;
  overflow: hidden !important;

  .mat-menu-content {
    padding-bottom: 0px !important;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid #4cadcd;
    }

    .list-view {
      li {
        border-bottom: 1px solid rgba(192, 192, 192, 0.3);

        &:last-child {
          border-bottom: 0px;
        }

        .left {
          padding-right: 10px;

          .img-view-s {
            width: 40px;
            height: 40px;
            overflow: hidden;
            display: block;

            img {
              border-radius: 100%;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .right {
          .notify-btn {
            .btn {
              max-width: 125px;
              padding: 5px;

              &.btn-theme-border {
                border-color: #c0c0c0;
              }
            }
          }
        }
      }

      .notification-b-r {
        li {
          border-bottom: 1px solid #fff;

          &:last-child {
            border-bottom: 0px;
          }
        }
      }

      .border-left-view-blue {
        li {
          border-left: 5px solid #4cadcd;
        }
      }
    }
  }
}

.date-custom-15 {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
}

.visibilty-calender {
  position: absolute;
  opacity: 0;
  width: 0px;
  height: 0px;
}

.bg-date-img {
  background-image: url("/assets/img/ic_timer.svg");
  background-position: 97%;
  background-repeat: no-repeat;
}

.popup-600-width {
  @media (min-width: 1025px) {
    width: 600px;
  }
}

.m-mx-100 {
  max-width: 100%;
}

.dog-shit-listing {
  @media (max-width: 1100px) {
    max-width: 100%;
  }

  li {
    .avatar {
      width: 64px;
      height: 64px;
      display: block;

      @media (max-width: 991px) {
        // width: 60px;
        // height: 60px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 0px;
        border-radius: 100%;

        &.yellow-border {
          border: 4px solid #fed66b;
        }
      }
    }

    .color-blue {
      color: #326291 !important;
    }

    .color-b--80 {
      color: rgba(0, 0, 0, 0.8) !important;
    }
  }

  hr {
    border-bottom: 1px solid #e0e0e0;
  }

  @media (max-width: 767px) {
    .mob-w-100 {
      width: 100%;
    }

    .mob-flex-wrap {
      flex-wrap: wrap;
    }

    .mobile-ml-0 {
      margin-left: 0 !important;
      display: block;
      max-width: fit-content;
    }

    .mob-d-none {
      display: none;
    }
  }
}

.dog-shit-listing-mob {
  @media (max-width: 767px) {
    max-width: 110%;
  }
}

.mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: 18px;
    width: 30px;
    border-radius: 10px;
  }

  .mat-slide-toggle-thumb-container {
    top: 1px;
    left: 1px;

    .mat-slide-toggle-thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      box-shadow: none;
    }
  }

  .mat-slide-toggle-bar {
    background-color: #98b0c8;
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      transform: translate3d(11px, 0, 0) !important;

      .mat-slide-toggle-thumb {
        background-color: #fff;
      }
    }

    .mat-slide-toggle-bar {
      background-color: #18b532;
    }
  }
}

.app-profile-settings-dog-setting {
    .mat-tab-group {
        &.mat-primary {
            .mat-ink-bar {
                background-color: transparent;
            }
        }


    }
}

.contact-page {
  .dog-p {
    margin-top: -1rem;
  }

  ::-webkit-input-placeholder {
    color: #98b0c8;
  }

  :-ms-input-placeholder {
    color: #98b0c8;
  }

  ::placeholder {
    color: #98b0c8;
  }

  .ng-select {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          color: #98b0c8;
        }
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        border-color: rgba(192, 192, 192, 0.9);
      }
    }
  }

  textarea {
    &.form-control {
      &#aditionalMessage {
        height: 130px;
      }
    }
  }

  .bottom-view {
    img {
      margin-bottom: -2px;
    }
  }

  .bg-img-dog {
    background: url("./assets/img/ic_paw.png");
    background-position: 95% 60%;
    background-repeat: no-repeat;
  }
}

.input-combobox-top {
  .ng-select-container {
    .ng-value-container {
      .ng-input {
        top: 14px !important;
      }
    }
  }
}

/*after registration*/

.mobile-after-reg {
  @media (max-width: 991px) {
    &.onSidenavOpen {
      max-width: 100%;
    }

    .nav-side-header {
      border-bottom: 0px;
      min-height: 88px;
    }

    .my-pets-dropdown {
      .dropdown-toggle {
        display: flex;
        padding-right: 40px;
        padding-left: 10px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);

        span {
          margin-right: 10px;
          width: 25px;

          img {
            object-fit: cover;
            border-radius: 100%;
          }
        }

        &:after {
          border: solid #326291;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          content: "";
          top: 14px;
          right: 20px;
        }
      }

      border: solid 1px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      margin: 20px 30px 0 30px;
    }

    .mobile-cta {
      padding: 20px 30px;
      border-bottom: 1px solid #797979;
      margin-bottom: 20px;
    }

    .line-img {
      display: block;
    }

    .divide-m {
      margin-top: 20px;
      // border-bottom: 1px solid #797979;
    }
  }
}

/*after registration*/

.class_for_red_dot {
  position: relative;

  &:before {
    content: "";
    background-color: #ff4545;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0px;
    border-radius: 100%;
    right: 0px;
  }
}

.combox-issue-padding {
  .ng-select-container {
    .ng-value-container {
      .ng-input {
        padding: 10px;
      }
    }
  }
}

.notification-sidebar-menu {
  .card {
    border: 0px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .head-title-noti {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: rgba(192, 192, 192, 0.3);
    }

    .list-view {
      .border-left-view-blue {
        li {
          border-left: 5px solid #4cadcd;
          border-bottom: 1px solid rgba(192, 192, 192, 0.3);

          .left {
            padding-right: 10px;

            .img-view-s {
              width: 40px;
              height: 40px;
              overflow: hidden;
              display: block;
            }
          }

          a {
            padding: 0px;
            color: #4cadcd;
            font-size: 14px;
          }
        }
      }

      .notification-b-r {
        li {
          border-bottom: 1px solid #fff;

          a {
            padding: 0px;
            color: #4cadcd;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.hundeseite-teilen-popup {
  max-width: 1053px;
  width: 100%;
}

.custom-dropdown {
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  position: relative;
  color: #326291;

  @media (max-width: 375px) {
    max-width: 250px;
  }

  &:after {
    content: "";
    width: 8px;
    height: 8px;
    line-height: 1;
    border: none;
    background-image: url("/assets/img/ic-drop-down.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    display: flex;
    align-items: center;
    background-position: center;
    right: 12px;
    top: 50%;
    transform: translatey(-50%);
  }
}

.custom-dropdown-popup {
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  position: relative;

  > span {
    color: #666666;
    padding-left: 27px;
  }

  &:before {
    content: "";
    width: 25px;
    height: 25px;
    line-height: 1;
    background-image: url("/assets/img/dog-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    position: absolute;
    display: flex;
    align-items: center;
    left: 12px;
    top: 50%;
    transform: translatey(-50%);
  }

  &:after {
    content: url("/assets/img/ic-drop-down.png");
    width: 12px;
    height: 12px;
    line-height: 1;
    border: none;
    position: absolute;
    display: flex;
    align-items: center;
    right: 12px;
    top: 50%;
    transform: translatey(-50%);
  }
}

.custom-dropdown-popup-chip {
  margin-top: 20px;
  display: block;

  .mat-chip.mat-standard-chip {
    background-color: #fff;
    border: 1px solid #e6e7e9;
    height: auto;
    border-radius: 100px;

    mat-icon {
      color: #326291;
      opacity: 1;
    }

    .img---v {
      height: 25px;
      width: 25px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 8px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.information-popup {
  position: relative;
  padding-left: 24px;
  color: #666666;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 24px;
  margin-bottom: 16px;
  // &:before{
  //     content: "";
  //     width: 18px;
  //     height: 18px;
  //     line-height: 1;
  //     background-image: url("/assets/img/ic_info.png");
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     border: none;
  //     position: absolute;
  //     display: flex;
  //     align-items: center;
  //     left: 0px;
  //     top: 50%;
  //     transform: translatey(-50%);
  // }
}

.area-selection {
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 10px;

  .mat-checkbox {
    margin-bottom: 12px;
    font-family: inherit;

    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: 4px;
    }

    .mat-checkbox-label {
      font-weight: 400;
    }

    &.has-sub-checkbox {
      ~ ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          padding-left: 24px;
        }

        .mat-checkbox-label {
          font-weight: 400;

          > span {
            margin-bottom: 6px;
            display: inline-block;
          }

          .mat-checkbox {
            margin-bottom: 6px;

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

        > .mat-checkbox-layout {
          > .mat-checkbox-inner-container {
            margin: 0 13px 0 0;
          }
        }
      }
    }
  }

  h4.area-title {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    margin-bottom: 16px;
  }

  .checkbox--dog {
    .mat-checkbox {
      display: block;
    }
  }
}

.profile-card.select-person-card {
  .card-header {
    .title {
      width: 100%;

      .name {
        width: auto;
        margin-right: 15px;
        font-weight: 500;
        font-size: 16px;
      }

      .icon {
        height: 15px;
        width: 15px;
      }
    }
  }
}

.popup-searchbar {
  position: relative;

  ::-webkit-input-placeholder {
    /* Edge */
    color: #326291;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #326291;
  }

  ::placeholder {
    color: #326291;
  }

  input {
    width: 100%;
    border: 1px solid #e6e7e9;
    padding: 12px;
    border-radius: 8px;
    color: #326291;
    background-image: url("/assets/img/search-popup.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 15px 13px;
    padding-left: 46px;
    box-sizing: border-box;
  }
}

.mat-autocomplete-panel {
  .mat-option {
    height: auto;
  }

  .mat-option-text {
    button {
      &.person-select {
        .person-details {
          display: flex;
          align-items: center;
          margin-bottom: 0px;
          padding: 10px 0;

          .name-details {
            .name {
              font-size: 16px;
              color: #323232;
            }

            .type {
              color: #326291;
              font-size: 14px;
            }
          }

          .avatar {
            height: 43px;
            width: 43px;
            overflow: hidden;
            border-radius: 100px;
            margin-right: 10px;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      align-items: center;
      line-height: initial;
      height: auto;
      margin-bottom: 0.5rem;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      padding: 0px 10px;

      &:hover {
        background-color: transparent;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .img---v {
        width: 25px;
        height: 25px;
        overflow: hidden;
        display: block;
        border-radius: 100%;
        margin-right: 0.5rem;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      span {
        text-transform: capitalize;
      }
    }
  }
}

.person-card {
  padding: 14px 20px;
  border: 1px solid #e6e7e9;
  margin-top: 15px;
  border-radius: 8px;
  position: relative;

  .close-card {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .permission {
    max-width: 223px;
  }

  .person-details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .name-details {
      .name {
        font-size: 16px;
        color: #323232;
      }

      .type {
        color: #326291;
        font-size: 14px;
      }
    }

    .avatar {
      height: 43px;
      width: 43px;
      overflow: hidden;
      border-radius: 100px;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.info-error {
  color: #ff6666;
  opacity: 0.8;
  padding: 10px 0;
}

.info-text {
  opacity: 0.8;
  padding: 10px 0;
}

.link-card {
  display: flex;
  padding: 12px;
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  align-items: center;

  .action {
    button {
      background-color: #326291;
      color: #fff;
      border: none;
      padding: 8px 15px;
      border-radius: 100px;
    }
  }

  .link-details {
    margin-right: 10px;
    width: calc(100% - 100px);

    h4 {
      font-size: 14px;
      text-align: left;
      color: #000000;
      opacity: 0.8;
      font-weight: 500;
      margin: 0;
    }

    a {
      color: #326291;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }
}

.date-range-picker {
  display: flex;
  margin-top: 20px;

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  .date-picker-item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;

    .mat-date-range-input {
      width: 0;
    }

    > input {
      border: 1px solid #e6e7e9;
      padding: 8px 11px;
      border-radius: 8px;
      color: #326291;
    }

    .mat-datepicker-toggle {
      position: absolute;
      right: 0;
      top: 0;
      background-image: url("/assets/img/ic_calendar.svg");
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center;

      .mat-icon-button {
        visibility: hidden;
      }
    }
  }
}

.ng-select.custom-dropdown:after {
  content: none;
}

.with-eye-btn {
  input {
    border-right: 0;

    &.form-control:focus,
    &.custom-select:focus {
      ~ .input-group-append {
        .input-group-text {
          border-color: #fed66b;
        }
      }
    }

    &.valid-error {
      &.form-control:focus,
      &.custom-select:focus {
        ~ .input-group-append {
          .input-group-text {
            border-color: #ff6666;
          }
        }
      }

      ~ .input-group-append {
        .input-group-text {
          border-color: #ff6666;
        }
      }
    }
  }

  .input-group-append {
    .input-group-text {
      background-color: #fff;
      border: 1px solid #e6e7e9;
      color: #98b0c8;
      padding-right: 18px;
    }
  }
}

.sticky-global-open {
  padding-top: 100px;
}

.px-18-btn {
  padding-left: 18px;
  padding-right: 18px;
  min-width: auto;
}

.page-top-space {
  margin-top: 7rem;
}

.m-mx-full {
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}

.second-version-toggle {
  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      width: 34px;
      background-color: #326291;
    }

    .mat-slide-toggle-thumb-container {
      top: 3px;
      left: 3px;

      .mat-slide-toggle-thumb {
        width: 11px;
        height: 11px;
      }
    }

    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: #797979;
      }

      .mat-slide-toggle-thumb-container {
        left: 7px;
      }
    }
  }
}

.dog-d-r {
  .mat-form-field-wrapper {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-infix {
      order: 2;
      border: 0;
      margin-left: 0.8rem;

      .mat-date-range-input {
        .mat-date-range-input-container {
          span {
            font-size: 14px;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            color: #797979;
          }
        }
      }
    }

    .mat-datepicker-toggle {
      .mat-focus-indicator {
        .material-icons {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.dropdown-height-min {
  &.mat-menu-panel {
    min-height: auto;
    max-height: max-content;
  }
}

.btn-max-158 {
  max-width: 158px;
}

.btn-max-200 {
  max-width: 200px;
}

.btn-h-45 {
  height: 45px;
}

/*Footer-down css*/

body {
  height: 100vh;

  app-root {
    height: 100%;

    app-profile,
    app-login,
    app-register-step-one,
    app-forgot-password,
    app-impressum,
    app-term-conditions,
    app-privacy-policy,
    app-contact-us,
    app-register-step-two,
    app-reset-password,
    .common-page {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .registration-page {
        width: 100%;
      }

      app-page-profile-footer,
      app-public-footer {
        width: 100%;
        margin-top: auto;
      }
    }

    app-page, app-profile {
      height: 100%;
    }
  }
}

/*Footer-down css*/

/*mat-datepicker-popup*/

.mat-datepicker-popup {
  .mat-datepicker-content {
    .mat-datepicker-content-container {
      .mat-calendar {
        button {
          span {
            color: #326291;
          }

          .mat-calendar-arrow {
            border-top-color: #326291;
          }
        }

        .mat-calendar-previous-button,
        .mat-calendar-next-button {
          &.mat-icon-button {
            width: 30px;
            height: 30px;
            background: #f7f8fc;
          }

          &:after {
            margin: 11.5px;
          }
        }

        .mat-calendar-previous-button {
          margin-right: 10px;
        }

        .mat-calendar-table-header {
          th {
            font-size: 14px;
            font-weight: 500;
            color: #34485e;
          }
        }

        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
            .mat-calendar-body-comparison-identical
          ) {
          background-color: #326291;
          border: 1px solid #326291;
          color: #fff;
        }

        .mat-calendar-body-selected {
          background-color: #fed66b;
          border: 1px solid #fed66b;
          color: #326291;
        }
      }
    }
  }
}

/*mat-datepicker-popup*/

/*Landing page design */

app-home {
  #app-row-header {
    // background-color: transparent;
  }
}

/*Landing page design */

/*personality*/

.gauge-acordion {
  .mat-accordion {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 10px 20px;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

/*personality*/

.form-group {
  &.input-with-icon {
    .input-container {
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        right: 15px;
        height: 16px;
        width: 16px;
        transform: translateY(-50%);

        svg {
          vertical-align: inherit;
        }
      }
    }
  }
}

.pac-container {
  // position: fixed !important;
  z-index: 10000 !important;
}

.grid-header-filter {
  .select-ap--control {
    .mat-form-field-wrapper {
      .mat-form-field-infix {
        .mat-select {
          border: 1px solid rgba(152, 176, 200, 0.72);
          font-family: "Poppins", sans-serif;
        }
      }
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      padding: 0px;
      margin: 0;

      .mat-form-field-flex {
        .mat-form-field-outline {
          .mat-form-field-outline-start {
            border-radius: 8px 0 0 8px;
          }

          .mat-form-field-outline-end {
            border-radius: 0px 8px 8px 0px;
          }

          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            border-color: rgba(152, 176, 200, 0.72);
          }
        }
      }

      .mat-form-field-suffix {
        .mat-icon-button {
          .mat-button-wrapper {
            svg {
              fill: #326291;
            }
          }
        }
      }
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: 1px;
      }
    }

    &.mat-form-field-can-float {
      &.mat-form-field-should-float {
        .mat-form-field-outline-gap {
          border-top-color: transparent !important;
        }
      }
    }
  }

  .mat-form-field-type-mat-date-range-input {
    .mat-form-field-infix {
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  .btn-refresh {
    border: 1px solid rgba(152, 176, 200, 0.72);
    height: 45px;
    border-radius: 8px;
  }
}

.edit-diary-drop {
  min-width: 50px !important;
  min-height: 25px !important;
  border-radius: 8px !important;

  .mat-menu-content {
    button {
      font-size: 0px;
    }
  }
}

.cdk-overlay-container .round-dialog-box {
  width: 100%;

  mat-dialog-container {
    max-width: 700px;
    width: 100%;
    margin: auto;
    border-radius: 100% !important;

    @media (min-width: 240px) and (max-width: 767px) {
      border-radius: 100% !important;
    }

    app-welcome-popup {
      margin: auto;

      .round-popup-container {
        padding-bottom: 100%;
        position: relative;

        &.welcome-popup-2 {
          position: relative;

          .round-popup-inner {
            .dog-image-inner {
              position: absolute;
              bottom: 0;
              width: 60%;
              z-index: 0;
              left: 20%;

              @media screen and (max-width: 420px) {
                width: 60%;
              }
            }

            .footer-content {
              position: relative;
              z-index: 1;

              .btn-theme {
                max-width: 200px;
                margin-top: 50px;
              }
            }

            .body-content {
              position: relative;
              z-index: 1;

              .content {
                ul {
                  li {
                    margin-bottom: 20px !important;
                  }
                }
              }
            }
          }
        }

        &.welcome-popup-3 {
          .round-popup-inner {
            background-image: url("/assets/img/round-back-bg-2.svg");
            background-size: 117%;
            background-position-x: 65%;
            background-position-y: 75%;

            .popup-main-body {
              .body-content {
                margin: auto;
                max-width: 468px;

                .content {
                  p {
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }

              .dog-image-inner {
                margin-bottom: -10%;
                text-align: center;
                margin-top: 7%;

                @media screen and (max-width: 420px) {
                  margin-bottom: -12%;
                  margin-top: 4%;
                }

                img {
                  max-width: 65%;
                  margin: auto;
                  width: 100%;
                }
              }

              .footer-content {
                .btn-theme {
                  font-size: 14px;
                  font-weight: 400;
                  max-width: 200px;
                  line-height: 22px;
                  margin-right: 0;
                  padding: 10px 20px;
                  margin-top: 30px;

                  @media screen and (max-width: 420px) {
                    font-size: 10px;
                    line-height: 10px;
                    padding: 5px 20px;
                    margin-top: 15px;
                  }
                }
              }

              .header-content {
                max-width: 450px;
                margin-top: 5%;

                .info-text {
                  text-align: center;
                  font-weight: 600;
                  line-height: 28px;
                  color: #4cadcd;
                  margin: 0;
                  padding: 0;
                  margin-bottom: 20px;

                  @media screen and (max-width: 420px) {
                    font-size: 14px;
                    line-height: 14px;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }

        &.welcome-popup-4 {
          .round-popup-inner {
            background-image: url("/assets/img/round-popup-bg-4.svg");
            background-size: 120%;
            background-position-x: 65%;
            background-position-y: -20%;

            .popup-main-body {
              .dog-image-inner {
                position: absolute;
                bottom: 0;
                max-width: 363px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
              }

              .footer-content {
                position: relative;
                z-index: 1;

                .btn-theme {
                  margin: 0 !important;
                  margin-top: 5px !important;

                  @media screen and (max-width: 420px) {
                    margin-top: 5px !important;
                  }
                }
              }
            }
          }
        }

        .round-popup-inner {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url("/assets/img/round-back-1.svg");
          background-repeat: no-repeat;
          background-position: 30%;
          background-size: 130%;

          .popup-main-body {
            padding: 20px;
            @media screen and (max-width: 575px) {
              padding: 40px;
            }
            .dog-image {
              margin-bottom: 35px;

              @media screen and (max-width: 420px) {
                margin-bottom: 17px;
              }

              img {
                @media screen and (max-width: 767px) {
                  max-height: 150px;
                }

                @media screen and (max-width: 575px) {
                  max-height: 100px;
                }

                @media screen and (max-width: 420px) {
                  max-height: 80px;
                }
              }
            }

            .header-content {
              max-width: 420px;
              margin: auto;

              @media screen and (max-width: 1024px) {
                max-width: 420px;
              }

              @media screen and (max-width: 575px) {
                // max-width: 250px;
                max-width: 80%;
                margin-top: 20px;
              }

              @media screen and (max-width: 420px) {
                // max-width: 162px;
                max-width: 80%;
                margin-top: 20px;
              }

              h4 {
                font-family: "Constantia";
                font-weight: bold;
                color: #326291;
                line-height: 45px;
                margin-bottom: 10px;
                font-size: 32px;

                @media screen and (max-width: 767px) {
                  font-size: 24px;
                  line-height: 32px;
                }

                @media screen and (max-width: 575px) {
                  font-size: 16px;
                  line-height: 18px;
                }
              }

              h5 {
                color: #4cadcd;

                @media screen and (max-width: 767px) {
                  font-size: 18px;
                  line-height: 28px;
                }

                @media screen and (max-width: 575px) {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }

            .body-content {
              max-width: 582px;

              @media screen and (max-width: 1024px) {
                max-width: 530px;
              }

              @media screen and (max-width: 575px) {
                max-width: 430px;
              }

              @media screen and (max-width: 420px) {
                max-width: 300px;
              }

              .content {
                .btn-theme {
                  margin: 0;
                  margin-top: 20px;
                }

                h4 {
                  font-family: "Constantia";
                  font-weight: bold;
                  color: #326291;
                  line-height: 45px;
                  margin-bottom: 10px;
                  font-size: 32px;

                  @media screen and (max-width: 767px) {
                    font-size: 24px;
                    line-height: 32px;
                  }

                  @media screen and (max-width: 575px) {
                    font-size: 16px;
                    line-height: 23px;
                  }
                }

                p {
                  color: rgba(66, 66, 66, 0.9);
                  font-size: 16px;
                  line-height: 28px;
                  font-weight: 400;
                  margin-bottom: 15px;

                  @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 24px;
                  }

                  @media screen and (max-width: 575px) {
                    font-size: 12px;
                    line-height: 20px;
                  }

                  @media screen and (max-width: 420px) {
                    font-size: 14px;
                    line-height: 16px;
                  }
                }

                ul {
                  @media screen and (max-width: 575px) {
                    margin-bottom: 0;
                  }

                  li {
                    color: #666666;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 400;
                    margin-bottom: 15px;

                    b {
                      font-weight: 600;
                    }

                    @media screen and (max-width: 1024px) {
                      font-size: 14px;
                      line-height: 20px;
                    }

                    @media screen and (max-width: 575px) {
                      font-size: 12px;
                      line-height: 16px;
                      margin-bottom: 10px !important;
                    }

                    @media screen and (max-width: 420px) {
                      font-size: 12px;
                      line-height: 12px;
                      margin-bottom: 5px !important;
                    }
                  }
                }
              }
            }

            .footer-content {
              max-width: 443px;
              margin: auto;

              @media screen and (max-width: 575px) {
                max-width: 250px;
              }

              @media screen and (max-width: 420px) {
                max-width: 202px;
              }

              .inputs-container {
                margin-bottom: 30px;

                @media screen and (max-width: 575px) {
                  margin-bottom: 20px;
                }

                @media screen and (max-width: 420px) {
                  margin-bottom: 10px;
                }

                .input {
                  display: flex;
                  justify-content: center;

                  ng-select {
                    .ng-select-container {
                      border: none;
                      box-shadow: 0px 0px 10px rgba(4, 3, 21, 0.1);
                      border-radius: 10px;

                      @media screen and (max-width: 420px) {
                        height: 35px !important;
                      }

                      .ng-placeholder {
                        @media screen and (max-width: 420px) {
                          font-size: 9px;
                        }
                      }
                    }

                    .ng-arrow-wrapper {
                      padding-left: 10px;

                      .ng-arrow {
                        transform: scale(0.5) rotate(45deg);
                      }
                    }

                    &:first-child {
                      margin-right: 10px;

                      @media screen and (min-width: 1200px) {
                        max-width: 120px;
                        width: 100%;
                      }
                    }
                  }
                }
              }

              .btn-theme {
                @media screen and (max-width: 575px) {
                  max-width: 100%;
                  margin-top: 10px;
                  width: auto;
                  font-size: 12px;
                }

                @media screen and (max-width: 420px) {
                  padding: 5px 24px;
                  font-size: 10px;
                }
              }

              .cancel-link {
                a {
                  color: rgba(66, 66, 66, 0.8);
                  margin-top: 20px;
                  display: inline-block;
                  position: relative;

                  @media screen and (max-width: 575px) {
                    font-size: 12px;
                  }

                  @media screen and (max-width: 420px) {
                    font-size: 10px;
                    margin-top: 5px;
                  }

                  &:after {
                    content: "";
                    width: 100%;
                    background-color: #fed66b;
                    position: absolute;
                    height: 1px;
                    bottom: 2px;
                    left: 0;
                  }

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.box-result {
  .card--img {
    .l-action-result {
      .progress-bar-bottom-result {
        .mat-progress-bar {
          border-radius: 4px;
          height: 6px;
          margin-bottom: 3px;

          .mat-progress-bar-buffer {
            background-color: #fff;
          }

          .mat-progress-bar-fill {
            &:after {
              background-color: #fed66b;
            }
          }
        }
      }
    }
  }
}

.carousel-arrow {
  width: 30px !important;
  height: 30px !important;
  background-color: #4daccb !important;
  background-repeat: no-repeat;
  background-size: 31px;
  background-position: 41%;
  border-radius: 10px !important;
  position: absolute !important;
  top: 82% !important;
  color: white !important;
  margin-top: -6px !important;
  z-index: 10 !important;
  margin-right: 0% !important;
  margin-left: 0% !important;
  cursor: pointer;
}

.carousel-dots.ng-star-inserted {
  top: 82.5% !important;
}

.carousel-dot {
  display: inline-block;
  border: 2px solid #b5d8d9 !important;
  border-radius: 100px;
  margin: 4px;
  width: 8px;
  height: 8px;
  background: #b5d8d9 !important;
}

.carousel-dot-active {
  background-color: #4daccb !important;
  width: 7% !important;
  border: 2px solid #4daccb !important;
  height: 10% !important;
}

.sidenav {
  max-width: 370px;

  @media only screen and (max-width: 575px) {
    max-width: 100%;
  }

  &.onSidenavClose {
    right: -380px;

    @media only screen and (max-width: 575px) {
      right: -575px;
    }
  }

  &.onSidenavOpen {
    right: 0;
  }
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
  display: none !important;
  pointer-events: none;
}

.custom-mat-pane-dog-step-one {
  .cdk-overlay-container {
  }

  .cdk-overlay-pane {
    max-height: 60%;
  }
}

.user-master-access {
  ng-select.ng-select .ng-select-container {
    height: 44px !important;

    .ng-value-container .ng-input > input {
      height: 40px;
    }

    .ng-value-label,
    .ng-select .ng-select-container .ng-placeholder {
      color: #2e2f2f;
      font-size: 14px;
    }
  }
}

.w-200-px {
  width: 200px;
}

.ngx-spinner-overlay {
  img {
    // width: 150px;
    width: auto;

    @media screen and (max-width: 768px) {
      max-width: 700px;
    }

    @media screen and (max-width: 575px) {
      max-width: 700px;
    }

    @media screen and (max-width: 420px) {
      max-width: 500px;
    }
  }
}

.text-clr-blue {
  color: #4cadcd;
}

.txt-chk-blue {
  color: #326291;
}

.profile-img {
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.4));
}

.c-profile-ads-main {
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.4));
}

.subscription-plans {
  .checkbox--dog .mat-checkbox-indeterminate .mat-checkbox-label,
  .checkbox--dog .mat-checkbox-checked .mat-checkbox-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.txt-color-grey {
  // color: rgba(0, 0, 0, 0.6);
  color: #8c939d;
}

app-page-profile-user-credit {
  .profile-card {
    border-radius: 10px;
    margin-bottom: 25px;
    box-shadow: 1px 1px 6px rgba(33, 43, 51, 0.1);
    border: none;

    .card-body {
      .chip-number {
        font-size: 22px;
        font-weight: 500;
        color: #326291;
      }
    }

    .card-header {
      border-radius: 10px 10px 0 0;
      background-color: #c7e5ef;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.no-actions {
        .title {
          width: 100%;
        }
      }

      .actions {
        min-width: 80px;
        display: flex;
        justify-content: flex-end;

        .action-btn {
          background-color: #fed66b;
          height: 27px;
          width: 27px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 5px;
          padding: 0;
          margin-left: 10px;

          img {
            height: 12px;
            width: 12px;
          }
        }
      }

      .title {
        display: flex;
        white-space: nowrap;
        overflow: hidden;

        .name {
          font-size: 18px;
          font-weight: 600;
          color: #326291;
          width: calc(100% - 34px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
        }

        .icon {
          height: 34px;
          width: 34px;
          margin-right: 10px;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

// from dashboard open
app-dashboard app-dog-sharing {
  .dogs-details-slider {
    @media (max-width: 1199px) and (min-width: 768px) {
      max-width: 100% !important;
    }
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.filter-popup {
  z-index: 10;
}

// Swiper Global Start
.swiper {
  width: 100%;
}

// .swiper-container-autoheight .swiper-slide {
//     height: auto !important;
// }

.swiper-container-vertical {
  max-height: 648px;

  @media (max-width: 767px) {
    max-height: 620px;
  }
}

.dash-verti-slider .swiper-container-vertical {
  max-height: 424px;

  @media (max-width: 767px) {
    max-height: 410px;
  }
}

.swiper-container-vertical .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 200px !important;
}

.fair-slider-ads {
  margin-bottom: 30px;

  .swiper-slide {
    img {
      border-radius: 11px;
      cursor: pointer;
      display: block;
      object-fit: cover;
      width: 100%;
    }
  }

  .swiper-container-vertical {
    .swiper-slide {
      cursor: pointer;
      border-radius: 11px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;

      a {
        height: 100%;
        width: 100%;
      }

      img {
        height: 100%;
      }
    }
  }
}

// Swiper Global End

app-person-test-page-two .select-12 {
  .mat-radio-container {
    width: 16px;
    height: 16px;
  }

  .mat-radio-outer-circle {
    width: 16px;
    height: 16px;
  }

  .mat-radio-inner-circle {
    width: 16px;
    height: 16px;
  }
}

// .coming-soon-img {
//     img {
//         width: 100% !important;
//     }
// }

.mat-radio-group .form-check.color-dark-gray label .mat-radio-label-content {
  color: #424242 !important;
}

.fair-btn a.dropdown-toggle {
  position: relative;
  padding: 10px 6px !important;
}

.fair-btn a.dropdown-toggle .mob-text-overflow {
  margin-left: 0 !important;
}

.fair-btn a.dropdown-toggle::after {
  right: 6px;
  vertical-align: middle;
  background-color: transparent !important;
  width: unset !important;
}

.person-pay-check {
  .mat-checkbox-layout {
    .mat-checkbox-label {
      white-space: normal !important;
    }
  }
}

.blue-color {
  color: #326291;
}

.blueLink {
  color: #4cadcd;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    color: #326291;
  }
}

.theme-primary-btn-outline {
  background: transparent;
  color: #326294;
  font-weight: bold;
  text-align: center;
  font-weight: 500;
  padding: 8px 30px;
  display: block;
  border-radius: 3em;
  border: 1px solid #326294;
  transition: all 0.5s ease;

  &:hover {
    color: #fff;
    background-color: #326294;
  }
}

.f-16 {
  font-size: 16px;
}

.two-btns {
  button {
    max-width: 378px;
    min-width: 200px;
    width: 100%;
    font-weight: bold;

    @media (max-width: 575px) {
      max-width: 100%;
    }
  }
}

.filter-breed-sw {
  margin-bottom: 0.8rem !important;

  .mat-slide-toggle-label {
    .mat-slide-toggle-bar {
      margin-right: 1rem;
    }

    .mat-slide-toggle-content {
      font-weight: 500 !important;
      font-family: "Poppins";
      color: #333;
      font-size: 14px;
    }
  }
}

.translater-google {
  max-width: 155px;
  min-width: 155px;

  &.nav {
    width: 100%;
    max-width: 100%;
    min-width: auto;

    .goog-te-gadget {
      width: 100%;
    }
  }

  .goog-te-combo {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    background-color: #fff;
    // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.mt-20 {
  margin-top: 20px;
}

app-dog-progress-bar.progress-bar-detail {
  .progress-container {
    height: 20px;

    .progress-bar {
      height: 20px;

      .progress-indicator {
        width: 40px;
        height: 34px;
      }
    }
  }
}

.remove-top-space-dialog-open {
  top: 0 !important;
}

.coming-soon-width {
  max-width: 40% !important;
}

@media (max-width: 1024px) {
  .coming-soon-width {
    max-width: 100% !important;
    width: auto;
  }
}

/* google translate strip hide css */
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body > .skiptranslate {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .mng-hdr-drop-width {
    width: 150px;

    app-page-profile-header-dog-list {
      .my-pets-dropdown {
        width: 150px !important;
      }
    }
  }
}

.subscription-plans .text-right.product-total-amt-clr {
  color: rgba(0, 0, 0, 0.6);
}

.user-profile-cmmn {
  .user-profile-details-box.user-p-details {
    border-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 10px;
  }
}

.user-profile-cmmn-hdr-img {
  .user-profile-bg-box {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.pt-33 {
  padding-top: 33px;
}

// for mibile view ppt result page padding
.pt-result {
  @media (max-width: 767px) {
    padding-top: 33px;
  }
}

// Chat notifications styles
.toast-container {
  .ngx-toastr:has(.chat-toast) {
    // box-shadow: 0 0 6px #999999;
    box-shadow: none;
    padding: 15px !important;
    width: 342px !important;
  }
}

.chat-message {
  color: $blue-1;
}

// .member-ship-container-wrapper{
//     .member-price-wrapper{
//         .tab-head-wrapper{
//             .mat-tab-header{
//                 border-bottom: none !important;   
//                 .mat-tab-labels{
//                     justify-content: center;
//                     margin-top: 90px;
//                     .mat-tab-label{
                      
//                         border-bottom: none !important;
//                             height: auto;
//                             border: 1px solid #cbd9e5;
//                             margin: 0;
//                             font-weight: 400;
//                             font-size: 16px;
//                             background-color: #4cadcd;
//                             color: #fff;
//                             padding: 0 30px;
//                             &.mat-tab-label-active{
//                                 padding: 10px 30px;
//                                 background: white;
//                                 color: #797979;
//                                 background-color: #fff;
//                                 font-weight: bold;
//                                 margin: 0;
    
//                             }
//                     }
//                 } 
//             } 
//         }
        
//     } 
// }
// .member-price-wrapper{
//     .mat-tab-group{
//         .mat-tab-header{
//             border-bottom: none;
//             .mat-tab-header-pagination{
//                 @media(max-width:767px){
//                     display: none;
//                 }
//             }
//             .mat-tab-label-container{
//                 margin: 90px 0px 50px 0px;
//                 display: block;
//                 @media(max-width:767px){
//                     overflow: auto;
//                 }
//                 .mat-tab-list{
//                     transform: inherit;
//                     width: max-content;
//                     margin: 0 auto;
//                     border: 1px solid #cbd9e5;
//                     border-radius: 10px;
//                     .mat-tab-labels{
//                         .mat-ripple{
//                             height: auto;
//                             border: none !important;
//                             margin: 0;
//                             font-weight: 400;
//                             font-size: 16px;
//                             background-color: #4cadcd;
//                             color: #fff;
//                             padding: 10px 40px;
//                             border: none;
//                             &.mat-tab-label-active{
//                                 padding: 10px 40px;
//                                 color: #797979;
//                                 background-color: #fff;
//                                 font-weight: bold;
//                                 border: none;
//                             }
//                             &:first-child{
//                                 border-top-left-radius: 10px;
//                                 border-bottom-left-radius: 10px;
//                                 border-right: 0px;
//                                 border-left: 1px solid #cbd9e5;
//                             }
//                             &:last-child{
//                                 border-top-right-radius: 10px;
//                                 border-bottom-right-radius: 10px;
//                                 border-right: 1px solid #cbd9e5;    
//                             }
//                         }
//                     }
//                     .mat-ink-bar{
//                         display: none;
//                     }
//                 }
//             }
//         }
        
//     }
// }
 
.name-error {
  margin-top: -14px;
}