$white-1: #FFFFFF;

$gray-1: #DFE7EF;
$gray-2: #EAEAEA;
$gray-3: #797979;
$gray-4: #424242;
$gray-5: rgba(0, 0, 0, 0.03);

$blue-1: #326291;
$blue-2: #4cadcd;
$blue-3: #C7E5EF;
$blue-4: #cbd9e5;

$yellow-1: #FED66B;
$yellow-2: #FFF5DA;